import React from "react";

const Details  = ({ApplicantsDetail}) =>{
    // console.log("Detail tab",ApplicantsDetail)
    return(
        <>
        <div>
            <div className="design-box">
                <div className="heading">
                    <h2>Detail information</h2>
                </div>
                <div className="box-content">
                    <div className="grid grid-cols-2 gap-2">
                            <div className="box-details">
                                <div className="mb-2">
                                    <label>Full Name</label>
                                    <span>{ApplicantsDetail.full_name}</span>
                                </div>
                                <div className="mb-2">
                                    <label>Notice Period</label>
                                    <span>{ApplicantsDetail.notice_period}</span>
                                </div>
                                <div className="mb-2">
                                    <label>Current Salary</label>
                                    <span>{ApplicantsDetail.current_salary}</span>
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="mb-2">
                                    <label>Experience</label>
                                    <span>{ApplicantsDetail.experience}</span>
                                </div>
                                <div className="mb-2">
                                    <label>Highest Qualification</label>
                                    <span>{ApplicantsDetail.highest_qualification}</span>
                                </div>
                                <div className="mb-2">
                                    <label>Expected Salary</label>
                                    <span>{ApplicantsDetail.expected_salary}</span>
                                </div>
                            </div>
                    </div>
                    <div className="select-btn">
                        <label>Skill set</label>
                        {ApplicantsDetail?.skill_set?.length > 0 ? (
                            ApplicantsDetail.skill_set.map((skill, index) => (
                                <button key={index}>{skill}</button>
                            ))
                        ) : (
                            <p>No skills available</p>
                        )}

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Details;