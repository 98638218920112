import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
    return (
        <>
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: 1 })}
        open={true}
      >
        <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="white"
                top="0"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
    </Backdrop>
      </>
        // <div className="flex items-center justify-center h-full">
            
        // </div>
    );
}

export default Loader;
