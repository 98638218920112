import React, { useEffect, useState } from "react";
import BriefcaseIcon from '@mui/icons-material/Work';
import MapPinIcon from '@mui/icons-material/LocationOn';
import ClockIcon from '@mui/icons-material/AccessTime';
import RupeesIcon from '@mui/icons-material/AttachMoney'; 
import UserIcon from '@mui/icons-material/Person';
import BookIcon from '@mui/icons-material/Book';
import BarChartIcon from '@mui/icons-material/BarChart';
import TagIcon from '@mui/icons-material/Label';
import LayersIcon from '@mui/icons-material/Layers';
import ListIcon from '@mui/icons-material/List';

// Helper function to format time
const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12;
  const formattedHour = hour.toString().padStart(2, '0');
  return `${formattedHour}:${minutes} ${ampm}`;
};

const JobPreviewForm = ({ formData }) => {
  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    setPreviewData(formData);
  }, [formData]);

  const splitByCapitalLetters = (str) => {
    str = String(str);
    return str.split(/(?=[A-Z])/);
};


  const InfoRow = ({ icon: Icon, label, value }) => (
    <div className="flex items-center space-x-3 mb-4 pb-4 border-b border-gray-200">
      <div className="text-gray-700 opacity-80">
        <Icon className="w-6 h-6 text-gray-600" />
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">{label}</p>
        <p className="text-lg font-semibold text-gray-800">{value}</p>
      </div>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto bg-white shadow rounded-xl overflow-hidden mr-8 mt-6">
      {/* Header */}
      <div className="bg-gradient-to-br from-[#C1EBF3] to-[#E4D0F4] text-[#516AF4] p-6 rounded-t-xl">
        <h1 className="text-2xl font-bold">{formData.job_title}</h1>
        <div className="flex items-center space-x-3 mt-2">
          <MapPinIcon className="w-5 h-5" />
          <span>{formData.location}</span>
        </div>
      </div>

      {/* Main Content */}
      <div className="grid md:grid-cols-2 gap-8 px-8 pt-8 pb-8">
        {/* Left Column */}
        <div>
          <InfoRow 
            icon={BriefcaseIcon} 
            label="Job Type" 
            value={splitByCapitalLetters(formData.job_types)} 
          />
          <InfoRow 
            icon={ClockIcon} 
            label="Job Mode" 
            value={splitByCapitalLetters(formData.job_modes)} 
          />
          <InfoRow 
            icon={BarChartIcon} 
            label="Work Experience" 
            value={
              !previewData.is_fresher_role
                ? `${previewData.work_experience_min} ${previewData.work_experience_min_unit} - ${previewData.work_experience_max} ${previewData.work_experience_max_unit}` 
                : 'Fresher'
            } 
          />
          <InfoRow 
            icon={RupeesIcon} 
            label="Offered Salary" 
            value={`${formData.expected_salary_min} - ${formData.expected_salary_max} ${formData.salary_currency} ${formData.salary_type}`} 
          />
        </div>

        {/* Right Column */}
        <div>
          <InfoRow 
            icon={LayersIcon} 
            label="Department" 
            value={formData.department} 
          />
          <InfoRow 
            icon={UserIcon} 
            label="Number of Openings" 
            value={formData.openings} 
          />
          <InfoRow 
            icon={BookIcon} 
            label="Education" 
            value={formData.education} 
          />
          <InfoRow 
            icon={ClockIcon} 
            label="Working Time" 
            value={`${formatTime(formData.working_time.start_time)} - ${formatTime(formData.working_time.end_time)}`} 
          />
        </div>
      </div>

      {/* Skills Section */}
      <div className="bg-gray-50 p-8 rounded-b-xl">
        <div className="flex items-center space-x-3 mb-4">
          <TagIcon className="w-6 h-6 text-gray-600 opacity-80" />
          <h2 className="text-xl font-semibold text-gray-800">Skills Required</h2>
        </div>
        <div className="flex flex-wrap gap-4">
          {formData.skill_set.map((skill, index) => (
            <span 
              key={index} 
              className="bg-gray-200 text-gray-800 text-xs font-medium px-4 py-2 rounded-full"
            >
              {skill.name}
            </span>
          ))}
        </div>
      </div>

      {/* Job Description */}
      <div className="p-8">
        <div className="flex items-center space-x-3 mb-4">
          <ListIcon className="w-6 h-6 text-gray-600 opacity-80" />
          <h2 className="text-xl font-semibold text-gray-800">Job Description</h2>
        </div>
        <p className="text-gray-700 leading-relaxed text-base">
          {formData.job_description}
        </p>
      </div>
    </div>
  );
};

export default JobPreviewForm;
