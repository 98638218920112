import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../config/config";

function CompanyDocUpload() {
  const [panDocumentId, setPanDocumentId] = useState(null);
  const [cinDocumentId, setCinDocumentId] = useState(null);
  const [gstDocumentId, setGstDocumentId] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [currDocument, setCurrDocument] = useState("metadata");

  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;

  const uploadDocument = async (url, file) => {
    // console.log("Uploading document:", file);
    try {
      const response = await axios.post(
        `${BASE_URL}${url}`,
        {
          document: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Return the response data if successful
      return response.data;
    } catch (error) {
      console.error("Error uploading document:", error);
      throw error;
    }
  };

  const updateDocument = async (documentId, file) => {
    console.log("Updating document with ID:", documentId);
    try {
      const formData = new FormData();
      formData.append("document", file);

      const response = await axios.put(
        `${BASE_URL}employer/company-documents/${documentId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        `Document with ID ${documentId} updated successfully:`,
        response.data
      );
      return response.data;
    } catch (error) {
      console.error(`Error updating document with ID ${documentId}:`, error);
      throw error;
    }
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company name is required")
      .min(2, "Company name must be at least 2 characters"),

    companyEmail: Yup.string()
      .email("Invalid email format")
      .required("Company email is required"),

    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "First name must contain only letters"),

    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Last name must contain only letters"),

    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),

    cin: Yup.string()
      .required("CIN is required")
      .matches(/^[A-Z0-9]+$/, "Invalid CIN format")
      .min(21, "CIN should have at least 21 characters"),

    pan: Yup.string()
      .required("PAN is required")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
      .min(10, "PAN should have exactly 10 characters"),

    gst: Yup.string()
      .required("GST is required")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}Z[A-Z0-9]{1}$/,
        "Invalid GST format"
      )
      .min(15, "GST should have exactly 15 characters"),

    documentCin: Yup.mixed()
      .required("CIN document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),

    documentPan: Yup.mixed()
      .required("PAN document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),

    documentGst: Yup.mixed()
      .required("GST document is required")
      .test(
        "fileSize",
        "File too large (max 5MB)",
        (value) => !value || value.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) =>
          !value ||
          ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
      ),
  });

  // fetch company details and upload documents and display them in the form
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}employer/company-details`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Company details fetched successfully:", response.data);
        const companyDetails = response.data;

        // Fetch and return a document as a Blob or URL
        console.log("Company details:",currDocument);
        const fetchDocument = async (documentType, documentId) => {
          try {
            const documentResponse = await axios.get(
              `${BASE_URL}employer/document-${currDocument}/${documentType}/${documentId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                responseType: "blob", // Handle files as binary data
              }
            );

            return new File(
              [documentResponse.data],
              companyDetails[`${documentType}_document`]?.filename,
              {
                type: documentResponse.headers["content-type"],
              }
            );
          } catch (err) {
            console.error(`Failed to fetch ${documentType} document:`, err);
            toast.error(`Failed to fetch ${documentType} document.`);
            return null; // Return null on failure
          }
        };

        console.log("Company details:", companyDetails);

        // Set document IDs into state
        setCinDocumentId(companyDetails.cin_document?.file_id);
        setPanDocumentId(companyDetails.pan_document?.file_id);
        setGstDocumentId(companyDetails.gst_document?.file_id);

        // Fetch all documents
        const [cinDocument, panDocument, gstDocument] = await Promise.all([
          companyDetails.cin_document?.file_id
            ? fetchDocument("cin", companyDetails.cin_document.file_id)
            : null,
          companyDetails.pan_document?.file_id
            ? fetchDocument("pan", companyDetails.pan_document.file_id)
            : null,
          companyDetails.gst_document?.file_id
            ? fetchDocument("gst", companyDetails.gst_document.file_id)
            : null,
        ]);
        

        // Set company details and documents into Formik
        formik.setValues({
          companyName: companyDetails.company_name,
          companyEmail: companyDetails.company_email,
          firstName: companyDetails.first_name,
          lastName: companyDetails.last_name,
          phoneNumber: companyDetails.phone_number,
          cin: companyDetails.company_cin,
          pan: companyDetails.company_pan,
          gst: companyDetails.company_gst,
          documentCin: cinDocument,
          documentPan: panDocument,
          documentGst: gstDocument,
        });
        
        if(companyDetails.company_name){
          setIsDisable(true);
        }
        localStorage.removeItem('status');  
        localStorage.setItem("status", companyDetails.status);

        console.log("Formik values updated with documents.", cinDocument);
      } catch (error) {
        console.error("Failed to fetch company details:", error);
        toast.error("Failed to fetch company details. Please try again.");
      }
    };

    fetchCompanyDetails();
  }, [currDocument]);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyEmail: "",
      firstName: "",
      lastName: "",
      cin: "",
      pan: "",
      gst: "",
      documentCin: null,
      documentPan: null,
      documentGst: null,
    },
    validationSchema,

    onSubmit: async (values) => {
      try {
        // Fetch company details based on CIN, PAN, and GST
        const response = await axios.post(
          `${BASE_URL}employer/company-documents/details`,
          {
            company_name: values.companyName,
            company_email: values.companyEmail,
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.phoneNumber,
            company_cin: values.cin,
            company_pan: values.pan,
            company_gst: values.gst,
            status: "pending",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Company details submitted successfully:", response.data);

        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (value instanceof File) {
            formData.append(key, value);
          } else if (value !== null) {
            formData.append(key, value.toString());
          }
        });

        // Update or upload documents based on their existing document ID
        const handleDocument = async (documentFile, documentId, uploadUrl) => {
          console.log("Handling document:", documentId, documentFile);
          if (documentFile) {
            if (documentId) {
              // Update existing document
              console.log(`Updating document with ID ${documentId}...`);
              await updateDocument(documentId, documentFile);
            } else {
              // Upload new document
              console.log(`Uploading new document to ${uploadUrl}...`);
              await uploadDocument(uploadUrl, documentFile);
            }
          }
        };

        // Handle CIN document
        await handleDocument(
          values.documentCin,
          cinDocumentId,
          "employer/company-documents/upload-cin"
        );

        // Handle PAN document
        await handleDocument(
          values.documentPan,
          panDocumentId,
          "employer/company-documents/upload-pan"
        );

        // Handle GST document
        await handleDocument(
          values.documentGst,
          gstDocumentId,
          "employer/company-documents/upload-gst"
        );
        if (gstDocumentId && panDocumentId && cinDocumentId) {
          toast.success("Documents updated successfully! ", {
            id: "upload-success",
          });
        } else {
          toast.success("Documents uploaded successfully! ", {
            id: "upload-success",
          });
        }
        // formik.resetForm();
        // setFiles({ cin: null, pan: null, gst: null });
      } catch (error) {
        toast.error(
          "Failed to upload documents or fetch details. Please try again."
        );
      }
    },
  });

  const renderErrorMessage = (fieldName) => {
    return formik.touched[fieldName] && formik.errors[fieldName] ? (
      <span className="text-red-500 text-sm">{formik.errors[fieldName]}</span>
    ) : null;
  };

const RenderFileUpload = (type) => {
  const fieldName = `document${type.charAt(0).toUpperCase() + type.slice(1)}`;
  const file = formik.values[fieldName]; // Get file from Formik's state
  const [previewFile, setPreviewFile] = useState(null);


  return (
    <div className="mb-4">
                  {/* Image Preview when hovering over the "View" Button */}
              {previewFile && (
                <img
                  src={previewFile}
                  alt="Document Preview"
                  className="absolute left-[10px] top-[100px] w-[60%] h-[70%] object-contain rounded-md"
                />

              )}
      <label className="block font-medium text-gray-700">
        Upload {type.toUpperCase()} Document
      </label>
      <div
        className={`grid ${file ? "grid-cols-2 gap-3" : "grid-cols-1"} mt-2`}
      >
        {/* File input */}
        <div className="w-full">
          <input
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            onChange={(e) => {
              formik.setFieldValue(fieldName, e.target.files[0]); // Store file in Formik state
            }}
            className="w-full p-[5px] border border-gray-300 rounded-md"
          />
        </div>

        {/* If a file is selected or already exists */}
        {file && (
          <div className="flex justify-between sm:justify-start items-center gap-4 relative">
            {/* View Button with hover effect */}
            <button
              type="button"
              onMouseEnter={() => {
                setCurrDocument("content");
                // Show the document preview when hovering over the "View" button
                const previewFile =
                  file instanceof File
                    ? URL.createObjectURL(file)
                    : `${BASE_URL}employer/documents/${type}/${file.file_id}`;
                setPreviewFile(previewFile); // Save the preview file URL to state
              }}
              onMouseLeave={() => {
                // Hide the document preview when the hover ends
                setPreviewFile(null);
              }}
              onClick={() => {
                setCurrDocument("content");
                // Open the file on click
                if (file instanceof File) {
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL, "_blank");
                } else if (file && file.file_id) {
                  const fileURL = `${BASE_URL}employer/documents/${type}/${file.file_id}`;
                  window.open(fileURL, "_blank");
                }
              }}
              className="relative text-blue-500 hover:underline px-3 py-1 border border-blue-500 rounded-md"
            >
              View
            </button>

            {/* Remove Button */}
            <button
              type="button"
              onClick={() => formik.setFieldValue(fieldName, null)} // Remove the file
              className="text-red-500 hover:underline px-3 py-1 border border-red-500 rounded-md"
            >
              Remove
            </button>
          </div>
        )}
      </div>
      {renderErrorMessage(fieldName)} {/* Render any validation errors */}
    </div>
  );
};

  return (
    <div className=" ">
      <Toaster />
      <div className="bg-white pt-6 rounded ">
        <form onSubmit={formik.handleSubmit}>
              {/* Image Preview when hovering over the "View" Button */}

          {/* Grid for Company Name and Company Email (1.5 and 1.5 columns) */}
          <div className="flex gap-3">
            <div className="col-span-2 w-[70%]">
              <label className="block font-medium text-gray-700">
                Company Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company name"
                disabled={ isDisable }
              />
              {renderErrorMessage("companyName")}
            </div>
            <div className="col-span-2 w-[70%]">
              <label className="block font-medium text-gray-700">
                Company Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="companyEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyEmail}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your company email"
                disabled
              />
              {renderErrorMessage("companyEmail")}
            </div>
          </div>

          {/* Grid for First Name, Last Name, and Phone Number */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-6">
            <div>
              <label className="block font-medium text-gray-700">
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your first name"
                disabled={ isDisable }
              />
              {renderErrorMessage("firstName")}
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter your last name"
                disabled={ isDisable }
              />
              {renderErrorMessage("lastName")}
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Phone Number<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter phone number"
                disabled={ isDisable }
              />
              {renderErrorMessage("phoneNumber")}
            </div>
          </div>

          {/* Grid for CIN, PAN, GST and file upload */}
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-6"> */}
          {/* CIN Input and File Upload */}
          <div className="flex flex-col sm:flex-row gap-3 mt-4">
            {/* CIN Input and File Upload */}
            <div className="sm:w-1/2 w-full mb-2">
              <label className="block font-medium text-gray-700">
                CIN<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="cin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cin}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md mb-2"
                placeholder="Enter CIN"
              />
              {renderErrorMessage("cin")}
            </div>
            <div className="sm:w-1/2 w-full">{RenderFileUpload("cin")}</div>
          </div>

          {/* PAN Input and File Upload */}
          <div className="flex flex-col sm:flex-row  gap-3 mt-4">
            <div className="sm:w-1/2 w-full">
              <label className="block font-medium text-gray-700">
                PAN<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="pan"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pan}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter PAN"
              />
              {renderErrorMessage("pan")}
            </div>
            <div className="sm:w-1/2 w-full">{RenderFileUpload("pan")}</div>
          </div>

          {/* GST Input and File Upload */}
          <div className="flex flex-col sm:flex-row  gap-3 mt-4">
            <div className="sm:w-1/2 w-full">
              <label className="block font-medium text-gray-700">
                GST<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="gst"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gst}
                className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                placeholder="Enter GST"
              />
              {renderErrorMessage("gst")}
            </div>
            <div className="sm:w-1/2 w-full">{RenderFileUpload("gst")}</div>
          </div>

          {/* </div> */}

          {/* Submit Button */}
          <div className="mt-6 text-center flex justify-end">
            <button
              type="button"
              className="theme-btn bordered-btn py-2.5  float-right"
              onClick={formik.handleSubmit}
            >
              <span className="color-text">Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyDocUpload;
