import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import InterviewSchedule from "../interviewSchedule";
import Modal from "@mui/material/Modal";
import toast, { Toaster } from "react-hot-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getJobs } from "../../../utils/employer.api/employer.api.services";
import SwitchToggle from "./toggleFunction";

const style = {
  position: "absolute",
  top: "20px",
  left: "50%",
  transform: "translate(-50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "16px",
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="gradientColors"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="rgba(26,217,223,1)" />
            <stop offset="100%" stopColor="rgba(168,36,254,1)" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        variant="determinate"
        {...props}
        size={50}
        thickness={3}
        sx={{
          "& .MuiCircularProgress-circle": {
            stroke: "url(#gradientColors)",
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontSize: "18px",
          }}
        >
          <span className="color-text font-bold">{`${Math.round(
            props.value
          )}%`}</span>
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const TotalApplicantsCards = () => {
  const open = useSelector((state) => state.auth.drawerOpen);
  const loginData = useSelector((state) => state.auth.Auth);
  const { jobId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [applicantsByStage, setApplicantsByStage] = useState({});
  const [stages, setStages] = useState([]);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [currentStage, setCurrentStage] = useState("");
  const [newStage, setNewStage] = useState("");
  const [reason, setReason] = useState("");
  const [selectAllForStage, setSelectAllForStage] = useState({});
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [draggedApplicantId, setDraggedApplicantId] = useState(null); // State for the dragged applicant
  const [dragReason, setDragReason] = useState(""); // State for the reason input
  const [jobDetails, setJobDetails] = useState(null);
  const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState("");
  const [interviewId, setInterviewId] = useState(""); // Ensure the interviewId is set from the selected applicant
  const [applicantData, setApplicantData] = useState("");
  const [showReasonText, setShowReasonText] = useState(false);
  const [moveStageName, setMoveStageName] = useState("");

  const handleCloseBulk = () => {
    setOpenBulkModal(false);
    setSelectedApplicants([]);
    setCurrentStage("");
    setNewStage("");
    setReason("");
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpeneBulk = () => {
    setOpenBulkModal(true);
  };
  const OpenInterviewModal = (applicantId, applicantData) => {
    // console.log("Selected applicant ID: ", applicantId);
    setOpenModal(true);
    setSelectedApplicantId(applicantId);
    setApplicantData(applicantData);
  };

  const handleCloseResponseModal = () => {
    setOpenReasonModal(false);
  };
  const handleSelectApplicant = (applicantId, stageName) => {
    setSelectedApplicants((prevSelected) => {
      let updatedSelected;
      if (prevSelected.includes(applicantId)) {
        updatedSelected = prevSelected.filter((id) => id !== applicantId);
      } else {
        updatedSelected = [...prevSelected, applicantId];
      }

      // Check if all applicants for the stage are selected
      const allApplicantsInStage =
        applicantsByStage[stageName]?.map((applicant) => applicant.id) || [];
      const allSelected = allApplicantsInStage.every((applicantId) =>
        updatedSelected.includes(applicantId)
      );

      // Update "Select All" state for the stage
      setSelectAllForStage((prevState) => ({
        ...prevState,
        [stageName]: allSelected,
      }));

      return updatedSelected;
    });
  };

  const handleSelectAll = (stageName) => {
    if (stageName === "Screening") {
      const isSelectedAll = selectAllForStage[stageName]; // Check current state for this stage
      const applicants =
        applicantsByStage[stageName]?.map((applicant) => applicant.id) || []; // Get all applicants for the stage

      setSelectedApplicants((prevSelected) => {
        if (isSelectedAll) {
          // Remove all applicants of this stage from selected
          return prevSelected.filter((id) => !applicants.includes(id));
        } else {
          // Add all applicants of this stage to selected
          return [...new Set([...prevSelected, ...applicants])];
        }
      });

      // Toggle select all state for this stage
      setSelectAllForStage((prevState) => ({
        ...prevState,
        [stageName]: !isSelectedAll,
      }));
    }
  };
  const fetchApplicants = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}employer/jobs/${jobId}/applicants`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      const { applicants_by_stage, stages, total_applicants } = response.data;
      console.log(response.data);
      setApplicantsByStage(applicants_by_stage);
      setStages(stages);
      setTotalApplicants(total_applicants);
      // setApplicantId(applicants_by_stage.id)
    } catch (error) {
      console.error("Error fetching applicants:", error);
    }
  };
  useEffect(() => {
    fetchApplicants();
  }, [jobId]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const movedApplicantId = result.draggableId; // Get the correct draggableId for the applicant
    const newStageName = destination.droppableId; // New stage name
    const draggedApplicant = applicantsByStage[source.droppableId]?.find(
      (applicant) => applicant.id === movedApplicantId
    );

    setMoveStageName(destination.droppableId);
    if (
      !draggedApplicant ||
      draggedApplicant.interview_status !== "scheduled"
    ) {
      if (
        destination.droppableId !== stages[stages.length - 2].name &&
        destination.droppableId !== stages[stages.length - 1].name
      ) {
        toast.error(
          "Interview not scheduled. Please schedule the interview before moving the applicant."
        );
        return; // Prevent further execution to avoid moving the applicant
      }
    }
    if (source.droppableId !== destination.droppableId) {
      // Open the modal and set the dragged applicant ID
      setIsInterviewCompleted(false);
      setDraggedApplicantId(movedApplicantId); // Set the dragged applicant ID correctly
      setCurrentStage(source.droppableId); // Current stage from where applicant is moved
      setNewStage(newStageName); // New stage to which applicant is moved
      setOpenReasonModal(true); // Open the modal to enter the reason
      setInterviewId(draggedApplicant.interview_id);

      setShowReasonText(
        destination.droppableId === "Hired" ||
          destination.droppableId === "Rejected"
      );
      fetchApplicants();
      console.log(destination.droppableId);
    }
  };
  const handleMoveApplicant = async () => {
    if (draggedApplicantId && currentStage && newStage) {
      // Check if the interview is required to be completed first
      if (isInterviewCompleted) {
        const payload = {
          applicant_ids: [draggedApplicantId],
          current_stage: currentStage,
          new_stage: newStage,
          reason: dragReason,
        };

        try {
          // First, call the interview completion API
          const interviewRes = await axios.post(
            `${config.apiUrl}employer/interviews/${interviewId}/complete`,
            {
              feedback,
              rating: parseInt(rating),
            },
            {
              headers: {
                Authorization: `Bearer ${loginData.access_token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (interviewRes.status === 200) {
            const res = await axios.post(
              `${config.apiUrl}employer/jobs/${jobId}/bulk-move`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${loginData.access_token}`,
                },
              }
            );

            if (res.status === 200) {
              toast.success(res.data.message);
              fetchApplicants(); // Refresh data to update the UI
              handleCloseResponseModal(); // Close the modal after the API call
            }
          } else {
            toast.error("Failed to complete the interview. Please try again.");
            // Do not close the modal if interview completion fails
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            toast.error(error.response.data.detail);
          } else {
            toast.error("An error occurred while completing the interview.");
          }
          // Do not close the modal on error
        }
      } else {
        // If the interview is not required to be completed
        const payload = {
          applicant_ids: [draggedApplicantId],
          current_stage: currentStage,
          new_stage: newStage,
          reason: dragReason,
        };

        try {
          // Now proceed to move the applicant
          const res = await axios.post(
            `${config.apiUrl}employer/jobs/${jobId}/bulk-move`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${loginData.access_token}`,
              },
            }
          );

          if (res.status === 200) {
            toast.success(res.data.message);
            fetchApplicants(); // Refresh data to update the UI
            handleCloseResponseModal(); // Close the modal after the API call
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            toast.error(error.response.data.detail);
          } else {
            toast.error("An error occurred while moving applicants.");
          }
          // Do not close the modal on error
        }
      }
    } else {
      alert("Please fill out all fields before submitting.");
    }
  };
  const fetchJobs = async () => {
    try {
      const response = await getJobs(loginData.access_token);

      if (response.status === 200) {
        const allJobs = [
          ...response.data["Opened Jobs"].jobs,
          ...response.data["On Hold Jobs"].jobs,
          ...response.data["Closed Jobs"].jobs,
        ];

        // Find the job with the matching jobId
        const job = allJobs.find((job) => job.id === jobId);

        if (job) {
          setJobDetails(job); // Set the job details in state
        } else {
          console.warn("Job not found");
        }
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [jobId]);
  if (!jobDetails) {
    return <div>Loading job details...</div>; // Loading state
  }
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div className="flex items-center mt-4">
          {/* <img src="images/icons/arrowLeft.svg" alt="Back" /> */}
          <div>
            <h2 className="menu-heading py-0">{jobDetails.title}</h2>
            <h2 className="sub-heading ml-2">
              <span className="mr-2">{jobDetails.job_modes}</span>
              <span className="mr-2">{jobDetails.job_type}</span>
              <span>{jobDetails.experience}</span>
            </h2>
          </div>
          <div className="ml-auto">
            <SwitchToggle jobId={jobDetails.id} jobDetails={jobDetails} />
          </div>
        </div>
        <div className="filters flex  items-center mt-4">
          <div className="md:ml-2">
            <h2 className="heading2">Total Applicants: {totalApplicants}</h2>
          </div>
          <div className="header-menus">
            {totalApplicants > 0 && selectedApplicants.length > 0 && (
              <>
                <button
                  type="button"
                  className="theme-btn bordered-btn h-8 px-3 text-sm mx-2 "
                  onClick={handleOpeneBulk}
                >
                  Move{" "}
                  <img
                    src="images/icons/arrowRight.svg"
                    className="max-w-3"
                    alt="Move"
                  />
                </button>
              </>
            )}
            {/* <input type="text" placeholder="Search" className="h-12 mx-2 " /> */}
          </div>
        </div>

        <div className="p-4 total-applicants">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="applicants" direction="horizontal">
              {(provided) => (
                <div
                  className="scrollable-section"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {stages.map((stage) => (
                    <Droppable
                      key={stage.name}
                      droppableId={stage.name}
                      type="APPLICANT"
                    >
                      {(provided) => (
                        <div
                          className={`job-section  hold ${stage.name
                            .toLowerCase()
                            .replace(" ", "-")}`}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div className="section-name mb-3">
                            <div className="dot"></div>
                            <span>{stage.name}</span>
                            {/* "Select All" functionality */}
                            {applicantsByStage[stage.name]?.length > 0 && (
                              <div className="ml-auto flex items-center">
                                {stage.name === "Screening" && (
                                  <>
                                    <input
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={
                                        selectAllForStage[stage.name] || false
                                      }
                                      onChange={() =>
                                        handleSelectAll(stage.name)
                                      }
                                    />
                                    <label>Select All</label>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <div>
                            {applicantsByStage[stage.name]?.map(
                              (applicant, index) => (
                                <Draggable
                                  key={applicant.id}
                                  draggableId={applicant.id.toString()}
                                  index={index}
                                  isDragDisabled={
                                    applicant.stage === "Hired" ||
                                    applicant.stage === "Rejected"
                                  }
                                >
                                  {(provided) => (
                                    <div
                                      className="applicants-card p-2 "
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {applicant.interview_status !==
                                        "scheduled" &&
                                        applicant.stage !== "Rejected" &&
                                        applicant.stage !== "Hired" &&
                                        stages[stages.length - 3].name !==
                                          applicant.stage && (
                                          <button
                                            type="button"
                                            className="schedule-btn"
                                            onClick={() =>
                                              OpenInterviewModal(
                                                applicant.id,
                                                applicant
                                              )
                                            }
                                          >
                                            Schedule Interview
                                          </button>
                                        )}
                                      {applicant.stage === "Rejected" && (
                                        <button
                                          type="button"
                                          className="schedule-btn bg-red-500"
                                          style={{ background: "red" }}
                                        >
                                          Rejected
                                        </button>
                                      )}
                                      {applicant.stage === "Hired" && (
                                        <button
                                          type="button"
                                          className="schedule-btn bg-red-500"
                                          style={{ background: "green" }}
                                        >
                                          Hired
                                        </button>
                                      )}
                                      
                                      <div className="flex gap-4  items-center mt-4">
                                        {/* User Details Section */}
                                        {applicant.stage === "Screening" && (
                                        <>
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={selectedApplicants.includes(
                                              applicant.id
                                            )}
                                            onChange={() =>
                                              handleSelectApplicant(
                                                applicant.id,
                                                stage.name
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                        <div className="user-detail flex flex-col justify-stretch items-stretch  ">
                                          <div className="flex  justify-between items-center gap-4 ml-3">
                                            <h3 className="text-lg font-semibold text-gray-800">
                                              {applicant.name}
                                            </h3>
                                            <p>
                                              Current CTC:{" "}
                                              <b className="text-gray-900">
                                                {applicant.current_ctc} LPA
                                              </b>
                                            </p>
                                          </div>
                                          <div className="flex justify-between items-center ml-3">
                                            <span className="text-sm text-gray-600">
                                              Applied on{" "}
                                              {new Date(
                                                applicant.applied_on
                                              ).toLocaleDateString()}
                                            </span>
                                            <p>
                                              NP:{" "}
                                              <b className="text-gray-900">
                                                {applicant.notice_period}
                                              </b>
                                            </p>
                                          </div>
                                          <span className="mt-2 text-blue-600 hover:underline ml-3">
                                            <Link to="">View Profile</Link>
                                          </span>
                                        </div>
                                        {/* Applicant Information Section */}
                                      <div className=" text-sm ml-6 text-gray-700">
                                        {applicant.interview_status ===
                                            "scheduled" && (
                                            <p className="scheduled">
                                              Interview:{" "}
                                              <b
                                                className={`${
                                                  applicant.interview_status ===
                                                  "scheduled"
                                                    ? "text-green-500"
                                                    : "text-yellow-500"
                                                }`}
                                              >
                                                {applicant.interview_status}
                                              </b>
                                            </p>
                                          )}
                                          {/* Score Section */}
                                          <div className="score flex flex-col justify-center items-center gap-2">
                                            <div className="relative flex justify-center items-center">
                                              <CircularProgressWithLabel value={applicant.score} />
                                            </div>
                                          </div>
                                        </div>

                                        {selectedApplicantId && (
                                          <>
                                            <InterviewSchedule
                                              openModal={openModal}
                                              closeModal={handleCloseModal}
                                              jobId={jobId}
                                              token={loginData.access_token}
                                              applicantId={selectedApplicantId}
                                              applicantData={applicantData}
                                              applicantStages={stages}
                                              refreshInterviews={
                                                fetchApplicants
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Box>
      <Modal
        open={openBulkModal}
        onClose={handleCloseBulk}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Toaster />
          <div className="px-4 pt-5" style={{ borderRadius: "16px 16px 0 0" }}>
            <h2 className="heading2 "> Move</h2>
          </div>
          <div className="modal-form px-4 py-5">
            <form className="form-theme" onSubmit={(e) => e.preventDefault()}>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-0">
                <div className="col-span-5 relative mb-4 ">
                  <label>Current Stage</label>
                  <select
                    className="w-full border border-gray-300"
                    value={currentStage}
                    onChange={(e) => setCurrentStage(e.target.value)}
                  >
                    <option value="" hidden disabled>
                      Select Stage
                    </option>
                    {stages.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-span-2 flex items-center justify-center">
                  <img
                    src="images/icons/arrowRight.svg"
                    className="w-6"
                    alt="arrow"
                  />
                </div>
                <div className="col-span-5 relative mb-4">
                  <label>Change stage to</label>
                  <select
                    className="w-full border border-gray-300"
                    value={newStage}
                    onChange={(e) => setNewStage(e.target.value)}
                  >
                    <option value="" hidden disabled>
                      Select Stage
                    </option>
                    {stages.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {/* {selectedApplicants.length > 0 && ( */}
              <div className="relative mb-4">
                <textarea
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Write your thoughts here..."
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
              {/* )} */}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="theme-btn bordered-btn py-2 mr-3"
                  onClick={handleCloseBulk}
                >
                  <span className="color-text">Cancel</span>
                </button>
                <button
                  type="button"
                  className="theme-btn bordered-btn py-2"
                  onClick={handleMoveApplicant}
                >
                  <span className="color-text">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openReasonModal}
        onClose={handleCloseResponseModal}
        aria-labelledby="drag-modal-title"
        aria-describedby="drag-modal-description"
      >
        <Box sx={style}>
          <Toaster />
          <div className="px-4 pt-5" style={{ borderRadius: "16px 16px 0 0" }}>
            <h2 className="heading2">
              Provide Reason for Moving "{moveStageName}"
            </h2>
          </div>
          <div className="modal-form px-4 py-5">
            <form className="form-theme" onSubmit={(e) => e.preventDefault()}>
              {showReasonText && (
                <div className="relative mb-4">
                  <textarea
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter reason for moving the applicant..."
                    value={dragReason}
                    onChange={(e) => setDragReason(e.target.value)}
                  ></textarea>
                </div>
              )}

              {/* Checkbox for interview completion */}
              {!showReasonText && (
                <div className="relative mb-4">
                  <input
                    type="checkbox"
                    id="interview-completed"
                    checked={isInterviewCompleted}
                    onChange={(e) => setIsInterviewCompleted(e.target.checked)}
                  />
                  <label htmlFor="interview-completed" className="ml-2">
                    Is the interview completed?
                  </label>
                </div>
              )}
              {/* Conditionally show feedback and rating fields */}
              {isInterviewCompleted && (
                <>
                  <div className="relative mb-4">
                    <textarea
                      rows="2"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter feedback for the applicant..."
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="relative mb-4">
                    <input
                      type="number"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter rating (1-5)..."
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      min="1"
                      max="5"
                    />
                  </div>
                </>
              )}

              <div className="flex justify-end">
                <button
                  type="button"
                  className="theme-btn bordered-btn py-2 mr-3"
                  onClick={handleCloseResponseModal}
                >
                  <span className="color-text">Cancel</span>
                </button>
                <button
                  type="button"
                  className="theme-btn bordered-btn py-2"
                  onClick={handleMoveApplicant}
                >
                  <span className="color-text">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TotalApplicantsCards;
