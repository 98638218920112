import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../config/config';
import { useApplicantsContext } from '../all Applicants/applicantsContext';
import { useSelector } from 'react-redux';

const ResumeViewer = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const { applicantsId } = useApplicantsContext();
  const loginData = useSelector((state) => state.auth.Auth);


  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}employer/${applicantsId}/resume`, {
          headers: {
            'Authorization': `Bearer ${loginData.access_token}`,
        },
          responseType: 'blob',
        });
        
        // Create a URL for the PDF blob
        const pdfBlobUrl = URL.createObjectURL(response.data);
        setPdfUrl(pdfBlobUrl);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          style={{ width: '100%', height: '100%' }}
          title="Resume PDF"
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default ResumeViewer;
