import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import RightDrawer from "./ApplicantsDetail";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getUniqueRoles } from "../../../utils/employer.api/employer.api.services";
import { useApplicantsContext } from "./applicantsContext";


const DataTable = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectJob, setSelectJob] = useState('');
    const [selectByStatus, setSelectByStatus] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [applicants, setApplicants] = useState([]);
    const [totalApplicants, setTotalApplicants] = useState(0);
    const[roles,setRoles] = useState([])
    const loginData = useSelector((state) => state.auth.Auth);
    

    // Fetch applicants data
    const fetchUniqueRoles = async () =>{
        try{
            const response = await getUniqueRoles(loginData.access_token);
            setRoles(response.data)
            
        }catch(error){
            console.log(error)
        }

    }
    const fetchAllApplicants = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}employer/all-applicants`, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                params: {
                    applied_role: selectJob,
                    applicant_status: selectByStatus,
                    search: searchQuery
                }
            });
            setApplicants(response.data.applicants);
            setTotalApplicants(response.data.total_applicants);
        } catch (error) {
            console.error("Error fetching applicants", error);
        }
    };

    // Fetch data when filter or search inputs change
    useEffect(()=>{
        fetchUniqueRoles()
    },[])
    useEffect(() => {
        fetchAllApplicants();
    }, [selectJob, selectByStatus, searchQuery]);

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle dropdown and search input changes
    const handleChangeJob = (event) => {
        setSelectJob(event.target.value);
    };

    const handleChangeStatus = (event) => {
        setSelectByStatus(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Toggle drawer for applicant details
    const { setApplicantsId } = useApplicantsContext();

    const toggleDrawer = (open,id) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
        setApplicantsId(id)
        fetchAllApplicants()
    };

    const columns = [
        { id: 'applicant', label: 'Applicant', minWidth: 200 },
        { id: 'rating', label: 'Rating', minWidth: 100 },
        { id: 'stage', label: 'Stage', minWidth: 170, align: 'left' },
        { id: 'applied_role', label: 'Applied Role', minWidth: 170, align: 'left' },
        { id: 'applied_on', label: 'Applied On', minWidth: 170, align: 'left' },
        { id: 'details', label: 'See Details', minWidth: 170, align: 'left' }
    ];

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} />
                <h2 className="menu-heading">All Applicants</h2>

                {/* Filters */}
                <div className="filters flex justify-end mb-4">
                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                        <InputLabel id="job-select-label">Based on Job Listing</InputLabel>
                        <Select
                            labelId="job-select-label"
                            id="job-select"
                            value={selectJob}
                            onChange={handleChangeJob}
                            label="Based on Job Listing"
                        >
                            <MenuItem value="">All</MenuItem>
                            {roles.map((items)=>(
                            <MenuItem value={items}>{items}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                        <InputLabel id="status-select-label">Applicant Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={selectByStatus}
                            onChange={handleChangeStatus}
                            label="Applicant Status"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="in-review">In review</MenuItem>
                            <MenuItem value="rejected">Rejected</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1, width: "150px" }} size="small">
                        <TextField
                            id="outlined-search"
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            fullWidth
                            size="small"
                        />
                    </FormControl>
                </div>

                {/* Table */}
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="applicants table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applicants
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((applicant) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={applicant.id}>
                                            <TableCell>{applicant.name}</TableCell>
                                            <TableCell>
                                                <StarRateRoundedIcon sx={{ color: "#FFD928" }} /> {applicant.rating}
                                            </TableCell>
                                            <TableCell align="left">{applicant.stage}</TableCell>
                                            <TableCell align="left">{applicant.applied_role}</TableCell>
                                            <TableCell align="left">{applicant.applied_on}</TableCell>
                                            <TableCell align="left">
                                                <MoreHorizIcon onClick={toggleDrawer(true,applicant.id)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={totalApplicants}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </>
    );
};

export default DataTable;
