const data = {
    title: "Privacy Policy",
    description: [
      "At Hirree, we take your privacy seriously and are committed to safeguarding the confidentiality of your personal data. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website or engage with our hiring and HR consultancy services."
    ],
    points: [
      {
        title: "Information We Collect",
        data: [
          {
            description: [
              "a) Personal Information: When you engage our services, we may collect personal information, including but not limited to your name, email address, phone number, job title, and company details. This information is necessary for us to provide you with our services effectively.",
              "b) Website Usage Data: When you visit our website, we may collect certain information automatically, such as your IP address, browser type, operating system, and pages visited. This information helps us understand how users interact with our website and improve its functionality."
            ]
          }
        ]
      },
      {
        title: "Use of Information",
        data: [
          {
            description: [
              "a) Service Provision: We use the personal information you provide to deliver our hiring and HR consultancy services to you. This includes assisting with recruitment processes, providing HR advisory services, and tailoring our solutions to meet your specific needs.",
              "b) Communication: We may use your contact information to communicate with you about our services, respond to inquiries, and provide updates related to your engagement with Hirree.",
              "c) Website Improvement: The data collected from website usage helps us analyze user behavior, improve website navigation, and enhance user experience."
            ]
          }
        ]
      },
      {
        title: "Data Security",
        data: [
          {
            description: [
              "a) Confidentiality: We treat all personal and client information with the utmost confidentiality. Access to sensitive data is restricted to authorized personnel only.",
              "b) Data Protection Measures: We implement security measures, such as encryption and firewalls, to protect your personal information from unauthorized access, loss, or misuse.",
              "c) Third-Party Data Processors: In some cases, we may engage third-party service providers to assist with data processing, such as cloud storage or email services. We ensure that these providers adhere to strict data protection standards."
            ]
          }
        ]
      },
      {
        title: "Cookies and Tracking Technologies",
        data: [
          {
            description: [
              "a) Cookies: Our website may use cookies, which are small text files stored on your device to enhance user experience and provide customized content. Cookies help us remember your preferences, track website traffic, and analyze user behavior.",
              "b) Cookie Choices: By visiting our website, you consent to the use of cookies as described in this Privacy Policy. You may adjust your browser settings to disable cookies or receive a notification when cookies are sent. However, disabling cookies may limit certain functionalities of the website."
            ]
          }
        ]
      },
      {
        title: "Data Sharing",
        data: [
          {
            description: [
              "a) Third-Party Disclosure: Hirree does not sell, trade, or share your personal information with third parties for marketing purposes. We may, however, share your data with trusted partners who assist us in providing our services.",
              "b) Legal Compliance: We may disclose personal information if required to do so by law or in response to valid legal requests."
            ]
          }
        ]
      },
      {
        title: "Data Retention",
        data: [
          {
            description: [
              "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws. When your data is no longer needed, it will be securely disposed of or anonymized."
            ]
          }
        ]
      },
      {
        title: "Your Rights",
        data: [
          {
            description: [
              "a) Access and Correction: You have the right to access and correct any inaccuracies in your personal information held by Hirree.",
              "b) Withdraw Consent: You may withdraw your consent for the processing of your personal data at any time by contacting us."
            ]
          }
        ]
      },
      {
        title: "Updates to the Privacy Policy",
        data: [
          {
            description: [
              "We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. The updated version will be posted on our website, and the date of the latest revision will be indicated at the beginning of the policy."
            ]
          }
        ]
      },
      {
        title: "Contact Information",
        data: [
          {
            description: [
              "If you have any questions, concerns, or requests regarding this Privacy Policy or your personal data, please contact us at:",
              "Email: business@hirree.com",
              "By using our website and engaging our services, you consent to the terms outlined in this Privacy Policy. Thank you for entrusting Hirree with your hiring and HR consultancy needs."
            ]
          }
        ]
      }
    ]
  };
  
  export default data;
  