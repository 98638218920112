import axios from "axios";
import config from "./config";

const client = axios.create({});

client.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

const axiosClient = (
    endpoint,
    payload = {},
    method = "get",
    headers = {}
) => {
    let axiosConfig = {
        method: method.toLowerCase(),
        url: `${config.apiUrl}${endpoint}`,
        headers: {
            ...headers, // Properly spread the headers here
        },
    };

    // Attach params or data based on method type
    if (method === "get" || method === "GET") {
        axiosConfig.params = payload;
    } else {
        axiosConfig.data = payload;
    }

    return client(axiosConfig);
};

export default axiosClient;
