import React,{useState} from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import { useSelector ,useDispatch} from "react-redux";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { REMOVE_PARSE_CV_DATA,LOGOUT } from "../redux copy/actionTypes";
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius:"20px"
  };
  
const Header = (userType) => {


    const navigate = useNavigate();

    const loginData = useSelector((state) => state.auth.Auth);
    const dispatch = useDispatch()
    const handleLogout = () => {
        navigate(`${config.candidateUrl}login`);
        dispatch({ type: LOGOUT});
        dispatch({type:REMOVE_PARSE_CV_DATA});
        handleClose();
      };
      const [openModal, setOpenModal] = useState(false);
      const handleOpen = () => {
        setOpenModal(true)
      }
      const handleClose = () => {
        setOpenModal(false)
      }


      
    return (
        <header className=" mb-3 mt-0 py-4 relative">
            <div className="container mx-auto">
            <div className="flex justify-between items-center relative">
  
  {/* Left Section */}
  <div className="flex flex-col">
    <div className="flex items-end">
      <img 
        src="/images/christmas/santa.png" 
        alt="logo" 
        className="w-14 h-16" 
      />

      <Link to={`${config.baseUrl}`} className="text-3xl font-bold">
        <h1 className="logo">
          <span className="color-text">Hirree</span>
        </h1>
      </Link>
    </div>
  </div>

  {/* Right Section */}
  <div className="flex justify-end items-center">
    
    {!loginData.access_token ? (
      <div className="authen flex space-x-4 z-10">
        {userType.userType === 'employer' ? (
          <>
            <Link to={`${config.employerUrl}login`}>
              <button type="button" className="theme-btn bordered-btn">
                <span className="color-text">Login</span>
              </button>
            </Link>
            <Link to={`${config.employerUrl}signup`}>
              <button type="button" className="theme-btn text-white">
                Sign Up
              </button>
            </Link>
          </>
        ) : (
          <>
            <Link to={`${config.candidateUrl}login`}>
              <button type="button" className="theme-btn bordered-btn">
                <span className="color-text">Login</span>
              </button>
            </Link>
            <Link to={`${config.candidateUrl}signup`}>
              <button type="button" className="theme-btn text-white">
                Sign Up
              </button>
            </Link>
          </>
        )}
      </div>
    ) : (
      <button type="button" className="theme-btn text-white" onClick={handleOpen}>
        Logout
      </button>
    )}

    {/* Balloons Image */}
    <img 
        src="/images/christmas/ballons.png" 
        alt="balloons" 
        className="w-[200px] absolute top-0 right-0 z-0 -mt-4 -mr-10" 
      />
  </div>
</div>

            <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
          Are you sure want to logout ?
          </Typography>

          <div className='justify-center items-center mt-5 flex'>
          
            <button onClick={handleClose} color="primary" sx={{ mt: 2 }} className='py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300'>
              No
            </button>
            <button onClick={handleLogout} sx={{ mt: 2 }} className=' py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg'>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
            </div>
        </header>
    );
};

export default Header;
