import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar';
import Drawer from '../dashboard/Drawer';
import CalenderContent from './calenderContent';

export default function Calendar() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <CalenderContent />
    </Box>
  );
}
