import React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

function FeedBack({ open, setOpen, BASE_URL, token }) {
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      selectedOption: "",
      feedbackDetails: "",
      selectedFile: null,
    },
    validationSchema: Yup.object({
      selectedOption: Yup.string().required("Please select a feedback type"),
      feedbackDetails: Yup.string()
        .min(10, "Details must be at least 10 characters")
        .required("Feedback details are required"),
      selectedFile: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "File size must be less than 5MB",
          (value) => !value || value.size <= 5242880
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const feedbackData = {
        feedback_type: {
          bug_report: values.selectedOption === "Bug Report",
          feature_request: values.selectedOption === "Feature Request",
          user_experience: values.selectedOption === "User Experience Feedback",
          other: values.selectedOption === "Others",
        },
        details: values.feedbackDetails,
      };

      const formData = new FormData();
      formData.append("feedback", JSON.stringify(feedbackData));
      if (values.selectedFile) {
        formData.append("attachment", values.selectedFile);
      }

      try {
        await axios.post(`${BASE_URL}candidate/candidate/feedback`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Feedback submitted successfully!");
        resetForm();
        setOpen(false);
      } catch (error) {
        toast.error(
          error.response?.data?.detail ||
            "Failed to submit feedback. Please try again later."
        );
      }
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{
          ...style,
        //   background: "linear-gradient(to bottom right, #C1EBF3, #E4D0F4)",
           position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              xs: '90%', // Mobile screens
              sm: '80%', // Small screens
              md: '60%', // Medium screens
              lg: '50%', // Large screens
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
        }}>
        <Typography variant="h6" mb={2}>
          Feedback & Support
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>
              What would you like to tell us?{" "}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <RadioGroup
              name="selectedOption"
              value={formik.values.selectedOption}
              onChange={formik.handleChange}
            >
              {[
                "Bug Report",
                "Feature Request",
                "User Experience Feedback",
                "Others",
              ].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            {formik.touched.selectedOption && formik.errors.selectedOption && (
              <Typography color="error">
                {formik.errors.selectedOption}
              </Typography>
            )}
          </FormControl>

          <TextField
            label="Can you give us more details?"
            name="feedbackDetails"
            multiline
            rows={4}
            fullWidth
            value={formik.values.feedbackDetails}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ mb: 2 }}
            error={
              formik.touched.feedbackDetails &&
              Boolean(formik.errors.feedbackDetails)
            }
            helperText={
              formik.touched.feedbackDetails && formik.errors.feedbackDetails
            }
          />

          <Button
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            sx={{ mb: 2 }}
          >
            Attach File
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(event) =>
                formik.setFieldValue(
                  "selectedFile",
                  event.currentTarget.files[0]
                )
              }
            />
          </Button>
          {formik.values.selectedFile && (
            <Typography variant="body2">
              Attached: {formik.values.selectedFile.name}
            </Typography>
          )}
          {formik.errors.selectedFile && (
            <Typography color="error">{formik.errors.selectedFile}</Typography>
          )}

          <Grid container spacing={2} mt={3}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleClose}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" type="submit" className="theme-btn">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}

export default FeedBack;
