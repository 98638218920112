import React from "react";
import Footer from "../../directories/footer";
import config from "../../config/config";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const search = [
    { name: 'React' },
    { name: 'Manual Testing' },
    { name: 'UI/UX Designer' },
    { name: 'Automation Test Engineer' },
    { name: 'React' },
    { name: 'Management' }
];

const Candidate = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-center">
                    <div className="md:w-6/12">
                        <div className="hero-section">
                            <h3>Browse as a Candidate</h3>
                            <h1>Find Jobs That Fit You Perfectly</h1>
                            <p>
                                Utilize the power of AI with Hirree to find job opportunities that perfectly match your skills and experience. Maximize your chances of landing your dream job.
                            </p>

                            <div className="jon-input">
                                <div className="flex items-center mb-2">
                                    <img src="images/search.png" className="search" alt="search" />
                                    <input type="text" placeholder="Job title, Keyword..." className="form-input" />
                                </div>
                                <div className="flex items-center">
                                    <img src="images/location.png" className="location" alt="location" />
                                    <input type="text" placeholder="Your Location" className="form-input" />
                                </div>
                                <Link to={`${config.baseUrl}coming-soon`}>
                                    <button type="button" className="find-job mt-3">Find Job</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-6/12">
                        <div className="mac-img">
                            <img src="images/mac2.svg" alt="mac" />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn="fadeIn">
                <section className="popular-search">
                    <div className="container mx-auto">
                        <div className="flex flex-wrap">
                            <div className="md:w-6/12">
                                <p>Popular searches</p>
                                {search.map((item, index) => (
                                    <button key={index} type="button" className="theme-btn bordered-btn me-1 mb-2">
                                        <span className="color-text">{item.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section className="mt-0 working-img">
                    <img src="images/working.png" alt="working" />
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section className="hiree-right pt-md-5">
                    <div className="container mx-auto">
                        <h1><span className="color-text">Tailored Job Opportunities at Your Fingertips</span></h1>
                        <div className="flex flex-wrap items-center pt-5">
                            <div className="md:w-6/12 p-2">
                                <div className="hirree-text">
                                    <h3>Personalized Job Recommendations</h3>
                                    <p>Receive job recommendations specifically tailored to your resume and skills, ensuring you find the best opportunities that match your qualifications.</p>
                                </div>
                            </div>
                            <div className="md:w-6/12">
                                <div className="frames frame1">
                                    <div className="color-box"></div>
                                    <div className="job1 job">
                                        <img src="images/job1.png" alt="job" />
                                    </div>
                                    <div className="job job2">
                                        <img src="images/job2.png" alt="job" />
                                    </div>
                                    <div className="purple circle"></div>
                                    <div className="blue circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap py-5 mt-md-5 items-center">
                            <div className="md:w-6/12">
                                <div className="frames frame2">
                                    <div className="color-box"></div>
                                    <div className="job inter1">
                                        <img src="images/interview1.png" alt="applicant" className="img1" />
                                    </div>
                                    <div className="job inter2">
                                        <img src="images/interview2.png" alt="popup" />
                                    </div>
                                    <div className="purple circle"></div>
                                    <div className="blue circle"></div>
                                </div>
                            </div>
                            <div className="md:w-6/12 p-3">
                                <div className="hirree-text">
                                    <h3>Integrated Interview Scheduling</h3>
                                    <p>Schedule, manage, and track interviews seamlessly within the platform, ensuring a streamlined experience for both you and the candidates.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap py-md-5 items-center">
                            <div className="md:w-6/12">
                                <div className="hirree-text">
                                    <h3>Real-Time Application Tracking</h3>
                                    <p>Keep track of your job applications in real-time. Monitor the status of each application, from submission to interview and offer, all in one place.</p>
                                </div>
                            </div>
                            <div className="md:w-6/12">
                                <div className="frames frame1 d-flex align-items-center">
                                    <div className="color-box"></div>
                                    <div className="purple circle"></div>
                                    <div className="blue circle"></div>
                                    <div className="text-center">
                                        <div className="pointer">
                                            <span>Screening</span>
                                        </div>
                                        <div className="pointer light">
                                            <span>Test</span>
                                        </div>
                                        <div className="pointer light">
                                            <span>Interview</span>
                                        </div>
                                        <div className="pointer">
                                            <span>HR Round</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section className="hiree-right pt-md-5">
                    <div className="container mx-auto">
                        <h1><span className="color-text">How It Works</span></h1>
                        <div className="flex flex-wrap pt-md-5">
                            <div className="md:w-4/12">
                                <div className="work-line">
                                    <div className="icon">
                                        <div className="inner-icon"><img src="images/icons/contact.png" alt="contact" /></div>
                                    </div>
                                    <div className="line line1"></div>
                                    <div className="content">
                                        <h3>Sign up on hirree.com, upload your CV & opt in to receive job alerts.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-4/12">
                                <div className="work-line">
                                    <div className="icon">
                                        <div className="inner-icon"><img src="images/icons/file.png" alt="file" /></div>
                                    </div>
                                    <div className="line line2"></div>
                                    <div className="content">
                                        <h3>Start browsing the job board & apply for jobs with a click of a button.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-4/12">
                                <div className="work-line">
                                    <div className="icon">
                                        <div className="inner-icon"><img src="images/icons/rocket.png" alt="rocket" /></div>
                                    </div>
                                    <div className="line line3"></div>
                                    <div className="content">
                                        <h3>Check back in your account to see updates on your applications.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section>
                    <div className="container mx-auto">
                        <div className="looking">
                            <h2>Looking for a job opportunity?<br /> You are at the right place!</h2>
                            <Link to={`${config.candidateUrl}signup`}>
                                <button type="button" className="theme-btn">Sign up Now</button>
                            </Link>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <Footer />
        </>
    )
}
export default Candidate;
