import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DragDropFile from './handleUpload';
import {  useSelector } from 'react-redux';
import config from '../../../config/config';
import axios from 'axios';
import AppBar from '../Appbar';
import Header from '../../../directories/header';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: "10px"
};

export default function UploadResumeModal() {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const loginData = useSelector((state) => state.auth.Auth);
    const fetchProfileData = async () => {
        try {
            const res = await axios.get(`${config.apiUrl}candidate/profile`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });
            console.log("satte",res.data)

            if(res.data && Object.keys(res.data).length > 0){
                setOpen(false)
            }
        }catch(error){console.log(error)}
    }
    useEffect(() => {
        fetchProfileData()
    }, []);
    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            {/* <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            > */}
            <Header/>
                <Box sx={{p:5}} >
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-2 text-black '>
                        Upload Your Resume
                    </Typography>
                    <p className='text-center mt-3'>And let the right job find you!</p>
                    <div className=' justify-center mt-5 py-5'>
                        <DragDropFile />
                    </div>
                </Box>
            {/* </Modal> */}
        </div>
    );
}
