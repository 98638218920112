const data = {
    title: "Terms and Conditions",
    description: [
      "Welcome to Hirree's AI based Hiring plateform Services. By accessing and using our website, you agree to comply with the following terms and conditions. Please read these terms carefully before engaging with our services."
    ],
    points: [
      {
        title: "Acceptance of Terms",
        data: [
          {
            description: [
              "By using our website and services, you acknowledge that you have read, understood, and accepted these terms and conditions in their entirety. If you do not agree with any part of these terms, please refrain from using our website and services."
            ]
          }
        ]
      },
      {
        title: "Service Engagement",
        data: [
          {
            description: [
              "Hirree offers AI based Hiring plateform Services to businesses seeking to enhance their human resources and streamline their hiring processes. The engagement of our services is subject to a signed agreement or contract outlining the scope, duration, and terms of the specific services to be provided."
            ]
          }
        ]
      },
      {
        title: "Confidentiality",
        data: [
          {
            description: [
              "We value the confidentiality of our clients' information and treat all data received during the course of our services as strictly confidential. Hirree agrees not to disclose or share any confidential information with third parties without prior written consent from the client, except when required by law."
            ]
          }
        ]
      },
      {
        title: "Service Limitations",
        data: [
          {
            description: [
              "While we strive to provide the best possible services, Hirree does not guarantee specific outcomes or results from our consultancy or hiring services. The success of any recruitment process or HR strategy may be influenced by various factors beyond our control."
            ]
          }
        ]
      },
      {
        title: "Client Responsibilities",
        data: [
          {
            description: [
              "Clients engaging our services are responsible for providing accurate and up-to-date information about their organization, job vacancies, and HR requirements. Clients should also cooperate in a timely manner with our team during the service engagement."
            ]
          }
        ]
      },
      {
        title: "Payment Terms",
        data: [
          {
            description: [
              "Payment terms and fees for our services will be specified in the signed agreement or contract. Hirree reserves the right to withhold services or terminate an engagement if payment is not made in accordance with the agreed-upon terms."
            ]
          }
        ]
      },
      {
        title: "Intellectual Property",
        data: [
          {
            description: [
              "All content, materials, and intellectual property provided on our website and through our services are the property of Hirree. Clients may not use, reproduce, distribute, or modify our content without prior written consent from Hirree."
            ]
          }
        ]
      },
      {
        title: "Limitation of Liability",
        data: [
          {
            description: [
              "Hirree shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from or related to the use of our website or services. Clients agree that our liability is limited to the fees paid for the specific services rendered."
            ]
          }
        ]
      },
      {
        title: "External Links",
        data: [
          {
            description: [
              "Our website may contain links to external websites and resources provided by third parties. Hirree does not endorse or have control over the content and availability of these external sites, and we are not responsible for any damages or losses resulting from their use."
            ]
          }
        ]
      },
      {
        title: "Modification of Terms",
        data: [
          {
            description: [
              "Hirree reserves the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. It is the responsibility of users to review these terms periodically for updates."
            ]
          }
        ]
      },
      {
        title: "Termination of Services",
        data: [
          {
            description: [
              "Hirree may terminate an engagement or suspend services if a client violates these terms and conditions or engages in any behavior deemed inappropriate or unethical."
            ]
          }
        ]
      },
      {
        title: "Governing Law",
        data: [
          {
            description: [
              "These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflicts of law principles."
            ]
          }
        ]
      }
    ]
  };
  
  export default data;
  