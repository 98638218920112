import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import DrawerHeader from '../dashboard/DrawerHeader';
import axios from 'axios';
import config from '../../../config/config';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import { getHiringStage, getProfileInfo } from '../../../utils/employer.api/employer.api.services';
import Typography from '@mui/material/Typography';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: "20px"
};

export default function ConfigurationStages() {
    const [isStages, setIsStages] = useState(false);
    const loginData = useSelector((state) => state.auth.Auth);
    const [hiringStages, setHiringStages] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newStageName, setNewStageName] = useState("");
    const [moveModalOpen, setMoveModalOpen] = useState(false)
    const [newStagePosition, setNewStagePosition] = useState(1);
    const [stageError, setStageErrors] = useState()
    const[openDelete,setOpenDelete]= useState(false)
    const [selectedStage, setSelectedStage] = useState(null); // Track which stage to delete


    const openDeleteModal = (stage) =>{
        setSelectedStage(stage);
        setOpenDelete(true)
        
    }
    const closeDeleteModal = () =>{
        setOpenDelete(false)
    }
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewStageName("");
    };

    const fetchHiringStages = async () => {
        try {
            const response = await getHiringStage(loginData.access_token);
            setHiringStages(response.data.stages);
            // console.log(response.data.stages)
            if (response.data.stages) {
                setIsStages(true);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        // fetchProfileInfo();
        fetchHiringStages();
    }, [loginData.access_token]);

    const AddStages = async (e) => {
        e.preventDefault();
        try {
            if (newStageName == "") {
                setStageErrors("Please enter stage name")
                return
            }
            const payload = {
                name: newStageName,
            };
            let res = await axios.post(`${config.apiUrl}employer/hiring-stages/append`, payload, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });

            if (res.status === 200) {
                toast.success("Stage added successfully!");
                handleCloseModal();
                fetchHiringStages();
                setStageErrors("")
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteStages = async () => {
        if (!selectedStage) return;
        try {
            let res = await axios.delete(`${config.apiUrl}employer/hiring-stages/${selectedStage}`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });

            if (res.status === 200) {
                toast.success("Stage deleted");
                fetchHiringStages();
                setOpenDelete(false)
            }
        } catch (error) {
            console.log(error);
        }
    };
    // const handleMoveStageOpen = (stage, index) => {
    //     setNewStageName(stage.name);
    //     setNewStagePosition(index + 1);
    //     setMoveModalOpen(true);
    // }
    const handleCloseMoveModal = () => {
        setMoveModalOpen(false);
        setNewStageName("");
    };
    const moveStage = async (dragIndex, hoverIndex) => {
        const draggedStage = hiringStages[dragIndex];

        // Update order in the frontend
        const updatedStages = update(hiringStages, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedStage],
            ],
        });

        setHiringStages(updatedStages);

        // Call API to update the backend
        try {
            const url = `${config.apiUrl}employer/hiring-stages/move-stage`;
            await axios.put(url, {
                name: draggedStage.name,
                new_position: hoverIndex,
            }, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });

            // toast.success("Stage moved");
        } catch (error) {
            console.error(error);
            toast.error("Failed to move stage");
        }
    };
    const StageItem = ({ stage, index, moveStage, EditStage, handleDeleteStages }) => {
        const ref = useRef(null);
        const [isEditing, setIsEditing] = useState(false); // Local state for edit mode
        const [updatedStageName, setUpdatedStageName] = useState(stage.name); // Local state for stage name

        const [, drop] = useDrop({
            accept: 'STAGE',
            canDrop: () => !stage.is_fixed,  // Prevent drop if stage is_fixed
            drop(item) {
                const dragIndex = item.index;
                const hoverIndex = index;

                if (!stage.is_fixed && dragIndex !== hoverIndex) {
                    moveStage(dragIndex, hoverIndex);
                }
            },
        });

        const [{ isDragging }, drag] = useDrag({
            type: 'STAGE',
            canDrag: () => !stage.is_fixed,  // Prevent drag if stage is_fixed
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        const handleSaveClick = async () => {
            console.log("Save button clicked"); // Debugging log
            if (updatedStageName !== stage.name) {
                await EditStage(stage.name, updatedStageName); // Call EditStage with correct parameters
            } 
            setIsEditing(false); // Exit edit mode after saving
        };


        return (
            <div
                ref={ref}
                className={`stage-item ${stage.is_default ? 'default-stage' : ''} ${isDragging ? 'dragging' : ''}`}
                style={{ opacity: isDragging ? 0.5 : 1 }}
            >
                <div className='circle'></div>
                <h3>Stage {index + 1}</h3>
                <input
                    value={updatedStageName}
                    readOnly={stage.is_default} // Make input editable only if it's not default
                    onFocus={() => setIsEditing(true)} // Enter edit mode on focus
                    onBlur={() => setIsEditing(true)} // Exit edit mode on blur
                    onChange={(e) => setUpdatedStageName(e.target.value)} // Update local state
                />

                <div className="stage-actions flex">
                    {!stage.is_default && ( 
                       <>
                        <img
                            src='images/icons/save.svg'
                            alt="Save"
                            className='cursor-pointer mr-2 p-1'
                            onClick={handleSaveClick}
                            hidden={!isEditing}
                        />
                        <img
                        src='images/icons/delete.svg'
                        alt="Delete"
                        className='cursor-pointer mr-2 p-1'
                       onClick={() => openDeleteModal(stage.name)}  
                    />
                    </>
                    )}
                </div>

            </div>
        );
    };

    const EditStage = async (previousStageName, updatedStageName) => {
        try {
            console.log(`Updating stage: ${previousStageName} to ${updatedStageName}`); // Debugging log
            let res = await axios.put(
                `${config.apiUrl}employer/hiring-stages/update/${previousStageName}`,
                { name: updatedStageName },
                {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                }
            );

            console.log('Response:', res.data); // Log response for debugging

            if (res.status === 200) {
                toast.success(res.data.message);
                fetchHiringStages(); // Fetch the latest stages
            }
        } catch (error) {
            console.error('Error updating stage:', error.response || error);
            toast.error("Error updating stage");
        }
    };



    return (
        <>
            <Toaster />
            <DndProvider backend={HTML5Backend}>
                <Toaster />
                <Box>
                    {isStages ? (
                        <>
                            <div className='flex items-center justify-between mt-4'>
                                <div>
                                    <h2 className='heading2'>Here’s the template you can edit and add new stages</h2>
                                </div>
                                {isStages ? (
                                    <button type="button" className="theme-btn text-white" onClick={handleOpenModal}>
                                        + Add Hiring Stages
                                    </button>
                                ) : ""}
                            </div>
                            <div className='stages mt-3'>
                                {hiringStages.map((stage, index) => (
                                    <StageItem
                                        key={stage.id}
                                        index={index}
                                        stage={stage}
                                        moveStage={moveStage}
                                        EditStage={EditStage}
                                        handleDeleteStages={handleDeleteStages}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className='mt-5'>
                            {/* <button type='button' className='bordered-btn theme-btn' onClick={CreateDefaultStages}>
                            <span className='color-text'>Create Hiring stages</span>
                        </button> */}
                            <p>Please fill the company info first....</p>
                        </div>
                    )}
                </Box>
            </DndProvider>
            <Modal open={openModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                        Add New Stage
                    </Typography>
                    <div>
                        <input
                            type='input'
                            placeholder='Stage name'
                            value={newStageName}
                            onChange={(e) => setNewStageName(e.target.value)}
                            className='w-full border border-gray-300 p-3 mt-4 rounded-md'
                        />
                        <span className='text-sm text-red-500'>{stageError}</span>
                    </div>
                    <div className='justify-center items-center mt-5 flex'>
                        <button onClick={handleCloseModal} className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300'>
                            Cancel
                        </button>
                        <button onClick={AddStages} className='py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg'>
                            Add Stage
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal open={moveModalOpen} onClose={handleCloseMoveModal}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                        Move Stage
                    </Typography>
                    <div>
                        <input
                            type='text'
                            placeholder='Stage name'
                            value={newStageName}
                            readOnly
                            className='w-full border border-gray-300 p-3 mt-4 rounded-md'
                        />
                        <input
                            type='number'
                            placeholder='New Position'
                            value={newStagePosition}
                            onChange={(e) => setNewStagePosition(e.target.value)}
                            className='w-full border border-gray-300 p-3 mt-4 rounded-md'
                        />
                    </div>
                    <div className='justify-center items-center mt-5 flex'>
                        <button onClick={handleCloseMoveModal} className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300'>
                            Cancel
                        </button>
                        <button onClick={moveStage} className='py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg'>
                            Move Stage
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal open={openDelete} onClose={closeDeleteModal}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                     Are you sure want to delete this stage
                    </Typography>
                    <div className='justify-center items-center mt-5 flex'>
                        <button onClick={closeDeleteModal} className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300'>
                            Cancel
                        </button>
                        <button onClick={handleDeleteStages} className='py-2.5 px-5 me-2 mb-2 text-white bg-red-500 text-sm font-medium theme-btn rounded-lg'>
                            Delete
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

