

import React, { useState } from "react";
import WithPassword from "./withPassword";
import WithOTP from "./withOtp";
import LoginFooter from "../loginFooter";

const CandidateLogin = () => {
  const [loginMethod, setLoginMethod] = useState('password');

  const switchToOtp = () => setLoginMethod('otp');
  const switchToPassword = () => setLoginMethod('password');

  return (
    <div className="p-md-4 p-2">
      {loginMethod === 'password' ? (
        <WithPassword switchToOtp={switchToOtp} />
      ) : (
        <WithOTP switchToPassword={switchToPassword} />
      )}
    </div>
  );
};

export default CandidateLogin;
