import React, { useState } from "react";
import toast from "react-hot-toast"; // For notifications
import config from "../../../config/config";
import { useSelector } from "react-redux";

const SwitchToggle = ({ jobId,jobDetails }) => {
    const [isOpen, setIsOpen] = useState(jobDetails?.is_accepting_applicants); // Tracks the current state
    const loginData = useSelector((state) => state.auth.Auth);

    // Function to handle toggle action
    const handleToggle = async () => {
        try {
            const response = await fetch(`${config.apiUrl}employer/jobs/${jobId}/toggle-applicants`, {
                method: "PATCH",
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                },
            });

            const data = await response.json();

            if (response.ok) {
                setIsOpen((prevState) => !prevState); // Update UI state
                toast.success(data.message);
            } 
        } catch (error) {
            console.error("Error toggling job status:", error);
            toast.error("An error occurred while updating the job status");
        }
    };

    return (
        <div className="flex items-center">
            <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer"
                    checked={isOpen}
                    onChange={handleToggle}
                />
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <span className="ml-3 text-sm font-medium">
                {isOpen ? "Open Applications" : "Closed Applications"}
            </span>
            
        </div>
    );
};

export default SwitchToggle;
