import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
// /employer/dashboard?interval=week&start_date=2024-11-21&end_date=2024-11-28

// Dashboard Component
export default function Dashboard() {
  const BASE_URL = config.apiUrl;
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;

  const [greeting, setGreeting] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const [jobOpenings, setJobOpenings] = useState([]);

  // Greeting message
  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  const today = new Date(); // Get today's date

  // Helper function to format dates as yyyy-mm-dd
  const getFormattedDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format
    }
    return null;
  };
  

  const [startDate, setStartDate] = useState(getFormattedDate(null));
  const [endDate, setEndDate] = useState(getFormattedDate(null));
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);


  const fetchDashboardData = async () => {
    try {
      // Ensure startDate and endDate are valid and formatted
      console.log(token)
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      const formattedStart = getFormattedDate(start);
      const formattedEnd = getFormattedDate(end);


      const response = await axios.get(start && end ? `${BASE_URL}employer/dashboard?&start_date=${formattedStart}&end_date=${formattedEnd}` :`${BASE_URL}employer/dashboard?}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDashboardData(response.data);
      setJobOpenings(response.data.last_three_openings);
    } catch (error) {
      toast.error("An error occurred. Please Complete your profile");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDashboardData(); 
  }, [startDate, endDate]);


  const barData = [];
  // create data for the bar chart on the selected range
  if (dashboardData.job_statistics) {
    const jobStatistics = dashboardData.job_statistics;
    const overview = jobStatistics.overview || []; 
  
    // Assuming startDate and endDate are in a valid Date object format
    const startDateStr = getFormattedDate(startDate) ||  getFormattedDate(today);
    const endDateStr = getFormattedDate(endDate) || getFormattedDate(today); 
  
  
    // Function to generate all dates between start and end date
    console.log("start",startDateStr);
    console.log("end",endDateStr);

    const generateDateRange = (start, end) => {
      const dateArray = [];
      let currentDate = new Date(start);
      const endDate = new Date(end);
  
      while (currentDate <= endDate) {
        dateArray.push(getFormattedDate(currentDate)); 
        currentDate.setDate(currentDate.getDate() + 1); 
      }
      return dateArray;
    };
  
    // Generate the range of dates
    const dateRange = generateDateRange(startDateStr, endDateStr);
  
    // Initialize barData with default values
    dateRange.forEach((day) => {
      const data = {
        day: day,
        views: 0, // Default value
        applied: 0, // Default value
      };
      barData.push(data);
    });
  
    // Update barData with actual statistics from overview
    overview.forEach((entry) => {
      const matchingDate = entry.date; // Date in overview
  
      // If the date matches one in the range, update views and applied
      const data = barData.find((data) => data.day === matchingDate);
      if (data) {
        data.views = entry.job_views || 0;
        data.applied = entry.job_applied || 0; // Update job_applied as well
      }
    });
  
    // Output the final bar data
    console.log(barData);
  }
  
  const formatDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return null;
  };

  // job statistics
  const applicationType = dashboardData.applicants_summary?.by_type;
  const totalApplicants = dashboardData.applicants_summary?.total;
  console.log("hi", totalApplicants);

  // Categories for the custom bar visualization
  const totalJobApplied = dashboardData.job_statistics?.job_applied;
  const totalJobView = dashboardData.job_statistics?.job_views;

  const categories = [
    {
      name: "Full-Time",
      value: applicationType?.["Full-time"] || 0,
      color: "#7C3AED", // Purple
    },
    {
      name: "Part-Time",
      value: applicationType?.["Part-time"] || 0,
      color: "#34D399", // Green
    },
    {
      name: "Internship",
      value: applicationType?.Internship || 0,
      color: "#FACC15",
    }, // Yellow
    {
      name: "Contractual",
      value: applicationType?.Contract || 0,
      color: "#EF4444",
    }, // Red
  ];

  const processedData = barData.map((item) => ({
    ...item,
    remainingViews: item.views - item.applied,
  }));

  const cardData = [
    {
      title: "New Applicants",
      value: `${dashboardData.new_applicants}`,
      icon: (
        <svg
          width="48"
          height="49"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="48" height="48" rx="24" fill="white" />
          <path
            d="M24 24.75C23.2583 24.75 22.5333 24.5301 21.9166 24.118C21.2999 23.706 20.8193 23.1203 20.5355 22.4351C20.2516 21.7498 20.1774 20.9958 20.3221 20.2684C20.4667 19.541 20.8239 18.8728 21.3483 18.3484C21.8728 17.8239 22.541 17.4668 23.2684 17.3221C23.9958 17.1774 24.7498 17.2516 25.4351 17.5355C26.1203 17.8193 26.706 18.2999 27.118 18.9166C27.5301 19.5333 27.75 20.2583 27.75 21C27.75 21.9946 27.3549 22.9484 26.6517 23.6517C25.9484 24.3549 24.9946 24.75 24 24.75ZM24 18.75C23.555 18.75 23.12 18.882 22.75 19.1292C22.38 19.3764 22.0916 19.7278 21.9213 20.139C21.751 20.5501 21.7064 21.0025 21.7932 21.439C21.88 21.8754 22.0943 22.2763 22.409 22.591C22.7237 22.9057 23.1246 23.12 23.561 23.2068C23.9975 23.2936 24.4499 23.249 24.861 23.0787C25.2722 22.9084 25.6236 22.62 25.8708 22.25C26.118 21.88 26.25 21.445 26.25 21C26.25 20.4033 26.0129 19.831 25.591 19.409C25.169 18.9871 24.5967 18.75 24 18.75ZM31 31.75C30.8019 31.7474 30.6126 31.6676 30.4725 31.5275C30.3324 31.3874 30.2526 31.1981 30.25 31C30.25 29.05 29.19 27.75 24 27.75C18.81 27.75 17.75 29.05 17.75 31C17.75 31.1989 17.671 31.3897 17.5303 31.5303C17.3897 31.671 17.1989 31.75 17 31.75C16.8011 31.75 16.6103 31.671 16.4697 31.5303C16.329 31.3897 16.25 31.1989 16.25 31C16.25 26.25 21.68 26.25 24 26.25C26.32 26.25 31.75 26.25 31.75 31C31.7474 31.1981 31.6676 31.3874 31.5275 31.5275C31.3874 31.6676 31.1981 31.7474 31 31.75Z"
            fill="black"
          />
        </svg>
      ),
    },
    {
      title: "Interviews Scheduled",
      value: `${dashboardData.interviews_scheduled}`,
      icon: (
        <svg
          width="48"
          height="49"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="48" height="48" rx="24" fill="white" />
          <path
            d="M14 24.5C14 20.729 14 18.843 15.172 17.672C16.343 16.5 18.229 16.5 22 16.5H26C29.771 16.5 31.657 16.5 32.828 17.672C34 18.843 34 20.729 34 24.5V26.5C34 30.271 34 32.157 32.828 33.328C31.657 34.5 29.771 34.5 26 34.5H22C18.229 34.5 16.343 34.5 15.172 33.328C14 32.157 14 30.271 14 26.5V24.5Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M19 16.5V15M29 16.5V15M14.5 21.5H33.5"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M30 29.5C30 29.7652 29.8946 30.0196 29.7071 30.2071C29.5196 30.3946 29.2652 30.5 29 30.5C28.7348 30.5 28.4804 30.3946 28.2929 30.2071C28.1054 30.0196 28 29.7652 28 29.5C28 29.2348 28.1054 28.9804 28.2929 28.7929C28.4804 28.6054 28.7348 28.5 29 28.5C29.2652 28.5 29.5196 28.6054 29.7071 28.7929C29.8946 28.9804 30 29.2348 30 29.5ZM30 25.5C30 25.7652 29.8946 26.0196 29.7071 26.2071C29.5196 26.3946 29.2652 26.5 29 26.5C28.7348 26.5 28.4804 26.3946 28.2929 26.2071C28.1054 26.0196 28 25.7652 28 25.5C28 25.2348 28.1054 24.9804 28.2929 24.7929C28.4804 24.6054 28.7348 24.5 29 24.5C29.2652 24.5 29.5196 24.6054 29.7071 24.7929C29.8946 24.9804 30 25.2348 30 25.5ZM25 29.5C25 29.7652 24.8946 30.0196 24.7071 30.2071C24.5196 30.3946 24.2652 30.5 24 30.5C23.7348 30.5 23.4804 30.3946 23.2929 30.2071C23.1054 30.0196 23 29.7652 23 29.5C23 29.2348 23.1054 28.9804 23.2929 28.7929C23.4804 28.6054 23.7348 28.5 24 28.5C24.2652 28.5 24.5196 28.6054 24.7071 28.7929C24.8946 28.9804 25 29.2348 25 29.5ZM25 25.5C25 25.7652 24.8946 26.0196 24.7071 26.2071C24.5196 26.3946 24.2652 26.5 24 26.5C23.7348 26.5 23.4804 26.3946 23.2929 26.2071C23.1054 26.0196 23 25.7652 23 25.5C23 25.2348 23.1054 24.9804 23.2929 24.7929C23.4804 24.6054 23.7348 24.5 24 24.5C24.2652 24.5 24.5196 24.6054 24.7071 24.7929C24.8946 24.9804 25 25.2348 25 25.5ZM20 29.5C20 29.7652 19.8946 30.0196 19.7071 30.2071C19.5196 30.3946 19.2652 30.5 19 30.5C18.7348 30.5 18.4804 30.3946 18.2929 30.2071C18.1054 30.0196 18 29.7652 18 29.5C18 29.2348 18.1054 28.9804 18.2929 28.7929C18.4804 28.6054 18.7348 28.5 19 28.5C19.2652 28.5 19.5196 28.6054 19.7071 28.7929C19.8946 28.9804 20 29.2348 20 29.5ZM20 25.5C20 25.7652 19.8946 26.0196 19.7071 26.2071C19.5196 26.3946 19.2652 26.5 19 26.5C18.7348 26.5 18.4804 26.3946 18.2929 26.2071C18.1054 26.0196 18 25.7652 18 25.5C18 25.2348 18.1054 24.9804 18.2929 24.7929C18.4804 24.6054 18.7348 24.5 19 24.5C19.2652 24.5 19.5196 24.6054 19.7071 24.7929C19.8946 24.9804 20 25.2348 20 25.5Z"
            fill="black"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="p-6  mt-10 w-full  bg-gray-50">
      {/* Header Section */}
      <div className="flex  justify-between item-strart sm:items-center bg-gray-50 mt-5 p-6 pr-10 sm:flex-row">
        {/* Left Section */}
        <div>
          {/* <h3 className="text-sm text-gray-500">Hello Ankit,</h3> */}
          <h1 className="text-2xl font-semibold text-gray-800">{greeting} , <p className=" inline-block text-gray-400 text-xl">{dashboardData.employer_name}</p></h1>
          <p className="text-sm text-gray-600">
          Here is your job listings statistic report
          {formatDate(startDate) && formatDate(endDate)
            ? ` from  ${formatDate(startDate)} to ${formatDate(endDate)}`
            : ` of Today`}.
          </p>
        </div>

        {/* Date Display */}
        <div className="grid grid-cols-2 gap-2 text-center">
          {/* Start Date */}
          <div className="relative">
            <div className="flex items-center bg-gray-100 px-4 py-2 rounded-md border border-gray-300">
              <span className="text-sm text-gray-700 hidden sm:block">
                {formatDate(startDate)=== null ? "Select Start Date" : formatDate(startDate)}
              </span>
              <button
                className="ml-2 text-blue-500"
                onClick={() => {
                  setIsStartDateOpen(!isStartDateOpen);
                  setIsEndDateOpen(false);
                }}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2905_16182)">
                    <path
                      d="M14.9999 4.6665H4.99992C4.07944 4.6665 3.33325 5.4127 3.33325 6.33317V16.3332C3.33325 17.2536 4.07944 17.9998 4.99992 17.9998H14.9999C15.9204 17.9998 16.6666 17.2536 16.6666 16.3332V6.33317C16.6666 5.4127 15.9204 4.6665 14.9999 4.6665Z"
                      stroke="#516AF4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3333 3V6.33333"
                      stroke="#516AF4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.66675 3V6.33333"
                      stroke="#516AF4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.33325 9.6665H16.6666"
                      stroke="#516AF4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.33341 13H6.66675V14.6667H8.33341V13Z"
                      stroke="#516AF4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2905_16182">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            {isStartDateOpen && (
              <div className="absolute right-10 left-0 top-full mt-2 z-50 w-full sm:w-auto sm:right-0 sm:max-w-xs">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date instanceof Date ? date : new Date(date));
                    setIsStartDateOpen(false);
                  }}
                  inline
                  dateFormat="dd-MMM-yyyy"
                  onClickOutside={() => setIsStartDateOpen(false)}
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>

          {/* End Date */}
          <div className="relative">
          
            <div className={`flex items-center bg-gray-100 px-4 py-2 rounded-md border ${formatDate(startDate) === null?" cursor-not-allowed opacity-50":" cursor-pointer opacity-100"} border-gray-300`} >
              <span className="text-sm text-gray-700 hidden sm:block">
                {formatDate(endDate) === null ? "Select End Date" : formatDate(endDate)}
              </span>
              <button
                className="ml-2 text-blue-500"
                onClick={() => {
                  setIsEndDateOpen(!isEndDateOpen);
                  setIsStartDateOpen(false);
                }}
                disabled={formatDate(startDate) === null}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                 >
                  <g clip-path="url(#clip0_2905_16182)">
                    <path
                      d="M14.9999 4.6665H4.99992C4.07944 4.6665 3.33325 5.4127 3.33325 6.33317V16.3332C3.33325 17.2536 4.07944 17.9998 4.99992 17.9998H14.9999C15.9204 17.9998 16.6666 17.2536 16.6666 16.3332V6.33317C16.6666 5.4127 15.9204 4.6665 14.9999 4.6665Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3333 3V6.33333"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.66675 3V6.33333"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.33325 9.6665H16.6666"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.33341 13H6.66675V14.6667H8.33341V13Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2905_16182">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>

            </div>
       
            {isEndDateOpen && (
              formatDate(startDate)?(

              <div
                className="absolute mt-2 z-50"
                style={{ position: "fixed", right: "10px" }}
              >
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date instanceof Date ? date : new Date(date));
                    setIsEndDateOpen(false);
                  }}
                  inline
                  dateFormat="dd-MMM-yyyy"
                  onClickOutside={() => setIsEndDateOpen(false)}
                  minDate={startDate}
                  maxDate={new Date()}
                />
              </div>
              ):(
                toast.error("Please select start date first")
              )
            )}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="flex flex-col gap-[32px] sm:flex-row ">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="bg-white w-[300px] h-[100px] rounded-[24px] shadow p-6 flex justify-between bg-gradient-to-br from-[#1AD9DF] to-[#A824FE]"
          >
            <div>
              <p className="text-white font-semibold text-2xl">{card.value}</p>
              <p className="text-white">{card.title}</p>
            </div>
            <div className="">{card.icon}</div>
          </div>
        ))}
      </div>

      <div className="flex flex-wrap lg:flex-nowrap gap-6 mt-4">
        {/* Left Section */}
        <div className="bg-white rounded-[24px] shadow p-6 flex-grow">
          {/* Header */}
          <div className="flex justify-between mb-6">
            <div>
              <h2 className="text-lg font-semibold">Job Statistics</h2>
              <p className="text-sm">Showing Job Statistics </p>
            </div>

          </div>

          {/* Main Content */}
          <div className="flex flex-col gap-6 sm:flex-row ">
            {/* Left Section: Overview Chart */}
            <div className="flex-grow border rounded-[24px]">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={processedData}
                barCategoryGap="20%"
                barGap={1}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="remainingViews"
                  stackId="a"
                  fill="#516AF4"
                  name="Job View"
                />
                <Bar
                  dataKey="applied"
                  stackId="a"
                  fill="#2AC6E2"
                  name="Job Applied"
                />
              </BarChart>
            </ResponsiveContainer>
            </div>

            {/* Right Section: Job Views and Applications */}
            <div className="flex flex-col gap-6">
              <div className="bg-white rounded-[24px] shadow p-6">
                <h2 className="text-lg font-semibold mb-4">Job Views</h2>
                <div className="text-center">
                  <p className="text-4xl font-bold">{totalJobView}</p>
                </div>
              </div>
              <div className="bg-white rounded-[24px] shadow p-6">
                <h2 className="text-lg font-semibold mb-4">Job Applied</h2>
                <div className="text-center">
                  <p className="text-4xl font-bold">{totalJobApplied}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-col gap-6 flex-grow lg:flex-none w-full lg:w-[320px] pr-0 l:pr-[48px]">
          {/* Job Openings */}
          <div className="bg-white rounded-[24px] shadow p-6 text-left">
            <h2 className="text-lg font-semibold mb-4">Job Openings</h2>

            <div className="flex">
              <p className="text-4xl font-bold">{dashboardData.job_openings}</p>
              <p className="text-gray-500 mt-3 mx-2">Jobs Opened</p>
            </div>
          </div>

          <div className="bg-white rounded-[24px] shadow p-6">
            {/* Header */}
            <h2 className="text-lg font-semibold mb-4">Applicants Summary</h2>

            {/* Total Applicants */}
            <div className="flex items-baseline">
              <p className="text-4xl font-bold">{totalApplicants}</p>
              <p className="text-gray-500 mt-2 ml-2">Applicants</p>
            </div>

            {/* Custom Bar Visualization */}
            <div className="flex items-center mt-4 w-full h-4 rounded-full overflow-hidden bg-gray-200">
              {categories.map((category, index) => (
                <div
                  key={index}
                  style={{
                    width:
                      totalApplicants > 0
                        ? `${(category.value / totalApplicants) * 100}%`
                        : "0%", // Handle cases where totalApplicants is 0
                    backgroundColor: category.color,
                  }}
                  className="h-full"
                  title={`${category.name}: ${category.value} (${(
                    (category.value / totalApplicants) *
                    100
                  ).toFixed(1)}%)`} // Tooltip
                ></div>
              ))}
            </div>

            {/* Legend */}
            <div className="grid grid-cols-2 gap-y-2 mt-4">
              {categories.map((category, index) => (
                <div key={index} className="flex items-center text-sm">
                  <span
                    className="inline-block w-4 h-4 rounded mr-1"
                    style={{ backgroundColor: category.color }}
                  ></span>
                  {category.name}: {category.value}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className=" flex justify-between items-center">
          <h2 className="text-lg font-semibold mb-4">Current Openings</h2>
          <Link
            to="/employer/job-listing"
            className="text-blue-500 text-l underline mx-12"
          >
            View All
          </Link>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6">
          {jobOpenings.map((job, index) => (
            <div
              key={index}
              className="relative p-6 rounded-[16px] shadow-lg bg-gradient-to-r from-[#efd8ff] to-[#c4f6ff] transition-all hover:shadow-xl hover:scale-105"
            >
              <div className="flex justify-start items-center gap-4">
                <div>
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_561_37034)">
                      <path
                        d="M13.6289 40.3587C13.6289 44.0237 10.6399 47.0172 6.97441 47.0172C3.30891 47.0172 0.316406 44.0237 0.316406 40.3587C0.316406 36.6937 3.30991 33.7002 6.97491 33.7002H13.6294L13.6289 40.3587ZM16.9834 40.3587C16.9834 36.6937 19.9769 33.7002 23.6419 33.7002C27.3069 33.7002 30.3004 36.6932 30.3004 40.3587V57.0262C30.3004 60.6912 27.3074 63.6847 23.6419 63.6847C19.9769 63.6847 16.9834 60.6912 16.9834 57.0262V40.3587Z"
                        fill="#DE1C59"
                      />
                      <path
                        d="M23.6419 13.6279C19.9769 13.6279 16.9834 10.6389 16.9834 6.97343C16.9834 3.30793 19.9769 0.31543 23.6419 0.31543C27.3069 0.31543 30.3004 3.30893 30.3004 6.97393V13.6284L23.6419 13.6279ZM23.6419 16.9824C27.3069 16.9824 30.3004 19.9759 30.3004 23.6409C30.3004 27.3059 27.3074 30.2994 23.6419 30.2994H6.97441C3.30941 30.2994 0.316406 27.3064 0.316406 23.6409C0.316406 19.9759 3.30991 16.9824 6.97491 16.9824H23.6419Z"
                        fill="#35C5F0"
                      />
                      <path
                        d="M50.3707 23.6409C50.3707 19.9759 53.3597 16.9824 57.0252 16.9824C60.6907 16.9824 63.6837 19.9759 63.6837 23.6409C63.6837 27.3059 60.6902 30.2994 57.0252 30.2994H50.3707V23.6409ZM47.0162 23.6409C47.0162 27.3059 44.0227 30.2994 40.3577 30.2994C36.6927 30.2994 33.6992 27.3064 33.6992 23.6409V6.97343C33.6992 3.30843 36.6922 0.31543 40.3577 0.31543C44.0227 0.31543 47.0162 3.30893 47.0162 6.97393V23.6409Z"
                        fill="#2EB57D"
                      />
                      <path
                        d="M40.3577 50.3717C44.0227 50.3717 47.0162 53.3607 47.0162 57.0262C47.0162 60.6917 44.0227 63.6847 40.3577 63.6847C36.6927 63.6847 33.6992 60.6912 33.6992 57.0262V50.3717H40.3577ZM40.3577 47.0172C36.6927 47.0172 33.6992 44.0237 33.6992 40.3587C33.6992 36.6937 36.6922 33.7002 40.3577 33.7002H57.0252C60.6902 33.7002 63.6837 36.6932 63.6837 40.3587C63.6837 44.0237 60.6902 47.0172 57.0252 47.0172H40.3577Z"
                        fill="#EBB02E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_561_37034">
                        <rect width="64" height="64" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  {" "}
                  <h3 className="text-lg font-semibold mb-1">{job.title}</h3>
                  <p className="text-gray-700 text-sm">
                    ₹{job.salary_min / 100000} - {job.salary_max / 100000} lakh
                    per annum
                  </p>{" "}
                </div>
              </div>
              <div className="mt-8 text-sm text-gray-600 flex justify-between">
                <p className="bg-[#F4F4F4] rounded-[24px] flex justify-center items-center gap-1 px-2">
                  <span className="inline-block ">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_465_27765)">
                        <path
                          d="M7.93793 1.75635C5.43404 1.75635 3.40894 3.78145 3.40894 6.28534C3.40894 9.68208 7.93793 14.6963 7.93793 14.6963C7.93793 14.6963 12.4669 9.68208 12.4669 6.28534C12.4669 3.78145 10.4418 1.75635 7.93793 1.75635ZM4.70293 6.28534C4.70293 4.49962 6.15221 3.05035 7.93793 3.05035C9.72364 3.05035 11.1729 4.49962 11.1729 6.28534C11.1729 8.1487 9.30957 10.9373 7.93793 12.6777C6.59217 10.9502 4.70293 8.12929 4.70293 6.28534Z"
                          fill="#898989"
                        />
                        <path
                          d="M7.93793 7.90296C8.83125 7.90296 9.55543 7.17878 9.55543 6.28547C9.55543 5.39215 8.83125 4.66797 7.93793 4.66797C7.04461 4.66797 6.32043 5.39215 6.32043 6.28547C6.32043 7.17878 7.04461 7.90296 7.93793 7.90296Z"
                          fill="#898989"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_465_27765">
                          <rect
                            width="15.528"
                            height="15.528"
                            fill="white"
                            transform="translate(0.17395 0.462402)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  {job.location}
                </p>
                <p className="bg-[#F4F4F4] rounded-[24px] flex justify-center items-center gap-1 px-2">
                  <span className="inline-block ">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_465_27771)">
                        <path
                          d="M8.40391 2.40332L1.28693 6.28531L3.87492 7.69577L3.87492 11.5778L8.40391 14.0493L12.9329 11.5778L12.9329 7.69577L14.2269 6.99054L14.2269 11.4613H15.5209L15.5209 6.28531L8.40391 2.40332ZM12.8164 6.28531L8.40391 8.69215L3.99138 6.28531L8.40391 3.87848L12.8164 6.28531ZM11.6389 10.8078L8.40391 12.5741L5.16892 10.8078L5.16892 8.401L8.40391 10.1673L11.6389 8.401L11.6389 10.8078Z"
                          fill="#898989"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_465_27771">
                          <rect
                            width="15.528"
                            height="15.528"
                            fill="white"
                            transform="translate(0.639893 0.462402)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  {job.experience}
                </p>
              </div>
              <div className="mt-8 flex justify-between items-baseline">
                <div className="mt-2 flex justify-between items-baseline gap-1">
                  <p className="text-4xl font-bold">{job.applicants_count}</p>
                  <p className="text-gray-500 text-xs">Applications</p>
                </div>
                <p className="text-xs text-gray-900">{job.posted_date}</p>
              </div>

              {/* buuton */}
              {/* <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                aria-label="Open job details"
              >
                <Link to={`${config.employerUrl}post-job`} state={{ job }}>
                  <svg
                    width="47"
                    height="48"
                    viewBox="0 0 47 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_b_465_27780)">
                      <circle
                        cx="23.356"
                        cy="24.0561"
                        r="23.292"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M30.2304 32.1436H16.4817"
                      stroke="#1E1E1E"
                      stroke-width="1.45575"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M29.0173 17.1816L17.6948 28.5041"
                      stroke="#1E1E1E"
                      stroke-width="1.45575"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M29.0173 25.4875V17.1816H20.7114"
                      stroke="#1E1E1E"
                      stroke-width="1.45575"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <filter
                        id="filter0_b_465_27780"
                        x="-96.9858"
                        y="-96.2857"
                        width="240.684"
                        height="240.684"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feGaussianBlur
                          in="BackgroundImageFix"
                          stdDeviation="48.5249"
                        />
                        <feComposite
                          in2="SourceAlpha"
                          operator="in"
                          result="effect1_backgroundBlur_465_27780"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_backgroundBlur_465_27780"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </Link>
              </button> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
