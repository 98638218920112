import React, { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const UpcomingInterviews = ({ upcoming_interviews}) => {
    // Step 1: Sort interviews by date
    const sortedInterviews = upcoming_interviews.sort(
        (a, b) => new Date(a.interview_date) - new Date(b.interview_date)
    );

    // Step 2: Group interviews by date
    const groupedByDate = sortedInterviews.reduce((acc, interview) => {
        const date = new Date(interview.interview_date).toDateString(); // Group by date string
        if (!acc[date]) acc[date] = [];
        acc[date].push(interview);
        return acc;
    }, {});

    const dates = Object.keys(groupedByDate); // Extract unique dates
    const [currentDateIndex, setCurrentDateIndex] = useState(0);

    // Handle edge case: No upcoming interviews
    if (!dates.length) {
        return (
            <div className="w-full md:w-1/3 bg-white border border-gray-200 rounded-2xl">
                <div className="flex justify-between pt-4 pl-6 pr-6">
                    <p className="text-lg font-semibold text-gray-700 mb-2">Upcoming Interviews</p>
                    <p className="text-gray-500 text-sm mb-2">0 of 0</p>
                </div>
                <div className="p-6 text-gray-500">No upcoming interviews scheduled.</div>
            </div>
        );
    }

    const currentDate = dates[currentDateIndex];
    const interviewsForCurrentDate = groupedByDate[currentDate];

    // Pagination functions
    const handlePreviousDate = () => {
        if (currentDateIndex > 0) setCurrentDateIndex(currentDateIndex - 1);
    };

    const handleNextDate = () => {
        if (currentDateIndex < dates.length - 1) setCurrentDateIndex(currentDateIndex + 1);
    };

    // Format time
    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const isPM = hours >= 12;
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
    };

    return (
        <div className="w-full md:w-1/3 bg-white border border-gray-200 rounded-2xl">
            {/* Header */}
            <div className="flex justify-between pt-4 pl-6 pr-6 border-b border-gray-200">
                <p className="text-lg font-semibold text-gray-700 mb-2">Upcoming Interviews</p>
                <p className="text-gray-500 text-sm mb-2">{`${currentDateIndex + 1} of ${dates.length}`}</p>
            </div>

            {/* Date and Navigation */}
            <div className="flex justify-between border-b border-gray-200 pl-6 pr-6 pt-2 pb-2">
                <p className="font-bold">{currentDate === new Date().toDateString() ? 'Today' : currentDate}</p>
                <div className="flex gap-2">
                    <KeyboardArrowLeftIcon
                        onClick={handlePreviousDate}
                        className={`cursor-pointer ${currentDateIndex === 0 ? 'text-gray-400' : ''}`}
                    />
                    <KeyboardArrowRightIcon
                        onClick={handleNextDate}
                        className={`cursor-pointer ${currentDateIndex === dates.length - 1 ? 'text-gray-400' : ''
                            }`}
                    />
                </div>
            </div>

            {/* Interview Details - Scrollable */}
            <div
                className="flex flex-col gap-4 pl-6 pr-6 pt-2 pb-2 max-h-80 overflow-y-auto"
                style={{ scrollbarWidth: 'thin', scrollbarColor: '#A0AEC0 transparent' }}
            >
                {interviewsForCurrentDate.map((interview, index) => (
                    <div
                        key={index}
                        className="flex p-1 justify-between items-center border border-gray-200 rounded-lg shadow-sm"
                    >
                        {/* Interview Time */}
                        <div className="text-gray-500">{formatTime(interview.interview_time.start_time)}</div>

                        {/* Interviewer Details */}
                        <div className="flex items-center gap-4 bg-gray-100 rounded-2xl p-3">
                            {/* Initial Icon */}
                            <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold">
                                {interview.interviewer_name.charAt(0)}
                            </div>
                            <div>
                                <p className="font-medium text-gray-700">
                                    {interview.interviewer_name} <br />
                                    <span className="text-sm text-gray-500">{interview.interview_stage}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UpcomingInterviews;
