import React from "react";
import CandidateSignUp from "./CandidateForm";
import EmployerSignup from "./EmployerSignup";
import LeftImage from "../design/LeftImage";
import { Link } from "react-router-dom";
import config from "../../config/config";

function Signup({ userType }) {
  const isCandidate = userType === "candidate";

  return (
    <div className="bg-[#F3F9FC] forms">
      <div className="mx-auto sm:max-w-full sm:max-w-[1200px] signup">
        <div className="grid grid-cols-12 gap-4">
          {/* Left Section */}
          <div className="md:col-span-7 col-span-12">
            <LeftImage />
          </div>

          {/* Right Section */}
          <div className="bg-white md:pt-28 md:px-16 p-5 md:col-span-5 col-span-12">
            {/* Header */}
            <div className="flex justify-center items-center mb-5">
              <h2 className="text-2xl font-bold">
                {isCandidate ? "Job Seeker Sign Up" : "Employer Sign Up"}
              </h2>
            </div>

            {/* Form Rendering */}
            {isCandidate ? <CandidateSignUp /> : <EmployerSignup />}

            {/* Footer */}
            <div className="my-5 relative">
              <span className="or">or</span>
            </div>
            <div className="footer-content">
              <p className="py-3">
                Already have an account?{" "}
                <Link to={`/${userType}/login`} state={{ userType }}>
                  Log In
                </Link>
              </p>

              <p>
                By clicking ‘Sign Up,’ you acknowledge that you have read and
                accept the{" "}
                <Link to={`${config.baseUrl}terms-and-conditions`}>
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to={`${config.baseUrl}privacy-policy`}>
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
