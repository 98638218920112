import React, { useState } from "react";

function UploadDocRedesignedV2() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    dob: "",
    proposal: "",
    companyName: "",
    website: "",
    location: "",
    teamSize: "",
    industry: "",
    yearEstablished: "",
    companyDescription: "",
    documents: [null, null, null],
  });

  const handleInputChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleFileChange = (index) => (e) => {
    const newDocuments = [...formData.documents];
    newDocuments[index] = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      documents: newDocuments,
    }));
  };

  const nextStep = () =>
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  const prevStep = () =>
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  const handleSubmit = () => console.log("Form submitted:", formData);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-[#C1EBF3] to-[#E4D0F4] font-sans">
      <div className="absolute top-8 left-8">
        <img src="/images/hirree.svg" alt="logo" className="h-12" />
      </div>
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8">
        <h3 className="text-2xl font-semibold text-center mb-10">
          Register Company
        </h3>
        {/* Step Indicators */}
        <div className="flex justify-between mb-8">
          {["Personal Details", "Company Info", "Documents"].map(
            (step, index) => (
              <div
                key={index}
                className={`flex flex-col items-center flex-1 transition-all ${
                  currentStep === index + 1
                    ? "opacity-100 border-b-4 border-blue-500"
                    : "opacity-50"
                }`}
              >
                <div
                  className={`w-10 h-10 flex items-center justify-center rounded-full text-white ${
                    currentStep === index + 1 ? "bg-blue-500" : "bg-gray-300"
                  }`}
                >
                  {index + 1}
                </div>
                <div className="mt-2 text-sm font-medium">{step}</div>
              </div>
            )
          )}
        </div>

        {/* Step 1: Personal Info */}
        {currentStep === 1 && (
          <div className="flex flex-col justify-center items-center space-y-6">
            <div className="gap-4" >
              {/* Full Name */}
              <input
                type="text"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleInputChange("name")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />

              {/* Company Email */}
              <input
                type="email"
                placeholder="Company Email Address"
                value={formData.email}
                onChange={handleInputChange("email")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />
            </div>

            {/* Navigation Button */}
            <div className="flex justify-end">
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 2: Company Info */}
        {currentStep === 2 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Company Name */}
            <div>
              <input
                type="text"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleInputChange("companyName")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />
            </div>
            {/* Website */}
            <div>
              <input
                type="text"
                placeholder="Website"
                value={formData.website}
                onChange={handleInputChange("website")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />
            </div>
            {/* Location */}
            <div>
              <input
                type="text"
                placeholder="Location"
                value={formData.location}
                onChange={handleInputChange("location")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />
            </div>
            {/* Team Size */}
            <div>
              <select
                value={formData.teamSize}
                onChange={handleInputChange("teamSize")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              >
                <option value="" disabled>
                  Select Team Size
                </option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-200">51-200</option>
                <option value="201-500">201-500</option>
                <option value="500+">500+</option>
              </select>
            </div>
            {/* Year of Establishment */}
            <div>
              <input
                type="text"
                placeholder="Year of Establishment"
                value={formData.yearEstablished}
                onChange={handleInputChange("yearEstablished")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              />
            </div>
            {/* Industry */}
            <div>
              <select
                value={formData.industry}
                onChange={handleInputChange("industry")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              >
                <option value="" disabled>
                  Select Industry
                </option>
                <option value="it">IT</option>
                <option value="healthcare">Healthcare</option>
                <option value="finance">Finance</option>
                <option value="education">Education</option>
              </select>
            </div>
            {/* Company Description (Full Width) */}
            <div className="col-span-1 md:col-span-2">
              <textarea
                rows="4"
                placeholder="Company Description"
                value={formData.companyDescription}
                onChange={handleInputChange("companyDescription")}
                className="p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-400"
              ></textarea>
            </div>
            {/* Navigation Buttons */}
            <div className="col-span-1 md:col-span-2 flex justify-between">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-md shadow-md hover:bg-gray-400 transition"
              >
                Previous
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 3: Documents */}
        {currentStep === 3 && (
          <div className="flex flex-col gap-4 ">
            {["CIN", "PAN", "GST"].map((doc, index) => (
              <div key={index} className="flex gap-3">
                <input
                  type="text"
                  placeholder={`Enter ${doc} Number`}
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
                <input
                  type="file"
                  onChange={handleFileChange(index)}
                  className="p-2 border border-gray-300 rounded-md w-[50%]"
                />
              </div>
            ))}
            <div className="flex justify-between">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-md shadow-md hover:bg-gray-400 transition"
              >
                Previous
              </button>
              <button
                onClick={handleSubmit}
                className="px-6 py-2 theme-btn text-white rounded-md shadow-md"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadDocRedesignedV2;
