import React, { useState } from "react";
import Header from "../../directories/header";
import Employer from "./employer";
import Candidate from "./candidate";
import Snowfall from 'react-snowfall';
import Snowfalls from "../design/SnowFalls";
import ChristmasLights from "../design/ChristmasLights";

const Home = () => {



    const [showComponent, setShowComponent] = useState('employer');
    const [activeButton, setActiveButton] = useState('employer');

    const handleShowEmployer = () => {
        setShowComponent('employer');
        setActiveButton('employer');
    };

    const handleShowCandidate = () => {
        setShowComponent('candidate');
        setActiveButton('candidate');
    };

    return (
        <>
        <Snowfalls />
        <Snowfall color="#F0F8FF" snowflakeCount={1500} className="pointer-events-none fixed inset-0 z-[9999]" />
        {/* <ChristmasLights /> */}
         
            <Header userType={showComponent} />
            <div className="container mx-auto">
                <button
                    type="button"
                    className={`me-2 bordered-btn theme-btn ${activeButton === 'employer' ? 'active' : ''}`}
                    onClick={handleShowEmployer}
                >
                    <span className="color-text">Employer</span>
                </button>
                <button
                    type="button"
                    className={`me-2 bordered-btn theme-btn ${activeButton === 'candidate' ? 'active' : ''}`}
                    onClick={handleShowCandidate}
                >
                    <span className="color-text">Candidate</span>
                </button>
            </div>
            {showComponent === 'employer' && <Employer />}
            {showComponent === 'candidate' && <Candidate />}
        </>
    );
};

export default Home;
