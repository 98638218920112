import React, { useEffect } from 'react';

const Snowfalls = () => {
  useEffect(() => {
    const snowflakes = document.querySelectorAll('.snowflake');
    
    snowflakes.forEach((snowflake) => {
      const delay = Math.random() * 5; // Random delay for falling
      const size = Math.random() * 30 + 20; // Random size between 20px and 50px
      const left = Math.random() * 100; // Random horizontal position (percentage)
      const speed = Math.random() * 5 + 3; // Random speed of falling
      const drift = Math.random() * 60 - 30; // Horizontal drift (left or right)

      // Apply random styles to each snowflake
      snowflake.style.animationDelay = `${delay}s`;
      snowflake.style.width = `${size}px`;
      snowflake.style.height = `${size}px`;
      snowflake.style.left = `${left}%`;
      snowflake.style.animationDuration = `${speed + 5}s`; // Longer duration for smooth falling
      snowflake.style.transform = `translateX(${drift}px)`; // Apply random horizontal drift
    });
  }, []);

  return (
    <div
      className="snowfall-container"
      style={{
        position: 'fixed',        // Make it fixed over the page
        top: 0,                   // Position at the top of the page
        left: 0,                  // Position at the left of the page
        width: '100%',            // Full width
        height: '100vh',          // Full height of the viewport
        pointerEvents: 'none',    // Disable interaction with the snowflakes
        zIndex: 9999,             // Ensure it stays on top of other content
        overflow: 'hidden',       // Prevent snowflakes from overflowing
      }}
    >
      {/* Snowflakes */}
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/ball1.png"
        className="snowflake"
        alt="Snowflake 1"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/ball1.png"
        className="snowflake"
        alt="Snowflake 2"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/ball1.png"
        className="snowflake"
        alt="Snowflake 3"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/ball1.png"
        className="snowflake"
        alt="Snowflake 4"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/bell3.png"
        className="snowflake"
        alt="Bell Snowflake"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/candy1.png"
        className="snowflake"
        alt="Candy Snowflake"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/gift3.png"
        className="snowflake"
        alt="Candy Snowflake"
      />
      <img
        src="https://sadupsoft.com/wp-content/plugins/weather-effect/assets/images/christmas/flack1.png"
        className="snowflake"
        alt="Snowflack Snowflake"
      />
    </div>
  );
};

export default Snowfalls;
