

import React, { useState,useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import config from '../../../config/config';
import { setParsedCvData, REMOVE_PARSE_CV_DATA } from '../../../redux copy/actionTypes';

const UploaFile = ({ onUploadSuccess }) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.Auth);
  const fileInputRef = useRef(null); // Add ref for the file input

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(e.target.files[0]);
    setFileName(selectedFile ? selectedFile.name : '');
    setErrorMessage('');
    return;
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('resume_file', file);
    dispatch({ type: REMOVE_PARSE_CV_DATA })
    try {
      const res = await axios.post(`${config.apiUrl}candidate/upload_cv`, formData, {
        headers: {
          'Authorization': `Bearer ${loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status == 200) {
        toast.success(res.data.message)
        fileInputRef.current.value = '';
        setFile(null);
        setFileName('');
        // window.location.href = `${config.candidateUrl}setting`;
      }
      onUploadSuccess();      
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  return (
    <>
      <Toaster />
      <div class="flex items-center  mt-3" style={{width:"50%"}}>
        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <img src='images/icons/upload.svg' className='mb-5'/>
          <p class="mb-2 text-md text-black ">Select a file or drag and drop here</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">JPG, PNG or PDF, file size no more than 10MB</p>
            {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>} 
            {fileName && <p className="text-black text-sm mt-2">{fileName}</p>} 
          </div>
          <input id="dropzone-file" ref={fileInputRef} type="file" onChange={handleFileChange} className="hidden" />
          <button onClick={handleUpload} className='block theme-btn text-white mt-4 float-right'>Upload</button>
        </label>
      </div>
    
    </>
  );
};

export default UploaFile;
