import React, { useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from "react-router-dom";
import { getInterviewPage } from "../../../utils/candidate.api/candidate.api.services";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { Dialog, DialogTitle, DialogContent,  TextField, Button,  IconButton, MenuItem,Select } from "@mui/material";
import {
    Close as CloseIcon,
    Event as EventIcon,
    Schedule as ScheduleIcon,
    ContentCopy as CopyIcon,
    Phone as PhoneIcon,
    Videocam as VideocamIcon,
    LocationCity as BuildingIcon,
    Person as PersonIcon,
    EventRepeat as EventRepeatIcon,

  } from '@mui/icons-material';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import {toast,Toaster} from "react-hot-toast";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InterviewContent = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const [value, setValue] = React.useState(0);
    const [logoUrls, setLogoUrls] = useState({});
    const [open, setOpen] = useState(false);
    const [openR, setOpenR] = useState(false);
    const [selectedInterview, setSelectedInterview] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [reason, setReason] = useState('');
    const BASE_URL = config.apiUrl;
    const token = loginData.access_token;


    const generateTimeSlots = (startHour, endHour) => {
        const slots = [];
        for (let i = startHour; i < endHour; i++) {
          slots.push(`${i}:00 - ${i + 1}:00`);
        }
        return slots;
      };
      
    const onClose = () => {
        setOpen(false);
        setSelectedInterview(null);
        };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const onCloseR = () => {
        setOpenR(false);
        setSelectedDate("");
        setSelectedTimeSlot("");
      };
    
      const handleOpenDialog = () => setOpenR(true);


    const validationSchema = Yup.object({
  date: Yup.string().required("Date is required"),
  timeSlot: Yup.string().required("Time slot is required"),
  reason: Yup.string()
    .min(10, "Reason must be at least 10 characters")
    .required("Reason is required"),
});

  const formik = useFormik({
    initialValues: {
      date: "",
      timeSlot: "",
      reason: "",
    },
    validationSchema,
    onSubmit: (values) => {
        const [start_time, end_time] = values.timeSlot.split(" - ");
        const formattedData = {
          proposed_date: values.date,
          proposed_time_slot: {
            start_time: start_time.trim(),
            end_time: end_time.trim(),
          },
          reason: values.reason.trim(),
        };

        axios
            .post(`${BASE_URL}candidate/interview/${selectedInterview?.id}/request-reschedule`, formattedData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log(response.data);
                toast.dismiss();
                toast.success("Interview rescheduled request send successfully");
            })
            .catch((error) => {
                console.error("Error rescheduling interview:", error.response ? error.response.data : error.message);
            });
        onCloseR(); 
      },
      
  });

// decline interview
const handleDecline = (id) => {
    axios
    .post(`${BASE_URL}candidate/interview/${id}/decline`, {}, {   
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((response) => {
        console.log(response.data);
        toast.dismiss();
        toast.success("Interview declined successfully");
    })
    .catch((error) => {
        console.error("Error declining interview:", error.response ? error.response.data : error.message);
    });
    onClose();
};


// accept interview
const handleAccept = (id) => {
    axios
    .post(`${BASE_URL}candidate/interview/${id}/accept`, {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((response) => {
        console.log(response.data);
        toast.dismiss();
        toast.success("Interview accepted successfully");
    })
    .catch((error) => {
        console.error("Error accepting interview:", error.response ? error.response.data : error.message);
    });
    onClose();
};



    // Determine the appropriate icon for interview type
  const getInterviewTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'phone':
        return <PhoneIcon className=" text-gray-400 mr-2" />;
      case 'in-office':
        return <BuildingIcon className=" text-gray-400 mr-2" />;
      case 'video call':
        return <VideocamIcon className=" text-gray-400 mr-2" />;
      default:
        return null;
    }
  };




    const [interviewData, setInterviewData] = useState({
        stats: {
            interviews_for_today: 0,
            pending_interviews: 0,
            completed_interviews: 0
        },
        upcoming_interviews: [],
        completed_interviews: []
    })
    const fetchAllInterviews = async () => {
        try {
            const response = await getInterviewPage(loginData.access_token);
            if (response.status == 200) {
                setInterviewData(response.data);
                console.log(response.data)
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching interviews:", error.response ? error.response.data : error.message);
        }
    }
    const fetchCompanyLogo = async (logo_id) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/company-logo/${logo_id}`, {
                responseType: 'blob'
            });
            if (response.status === 200) {
                const logoUrl = URL.createObjectURL(response.data);
                setLogoUrls((prev) => ({ ...prev, [logo_id]: logoUrl })); // Store logo URL with logo_id as key
            }
        } catch (error) {
            console.error("Error fetching company logo:", error.response ? error.response.data : error.message);
        }
    };
    const fetchAllCompanyLogos = (interviews) => {
        interviews.forEach(interviewDay => {
            interviewDay.interviews.forEach(interview => {

                if (!logoUrls[interview.company_logo]) {
                    fetchCompanyLogo(interview.company_logo);

                }
            });
        });
    };
    useEffect(() => {
        if (interviewData.upcoming_interviews) {
            fetchAllCompanyLogos(interviewData.upcoming_interviews);
        }
        if (interviewData.completed_interviews) {
            fetchAllCompanyLogos(interviewData.completed_interviews);
        }
    }, [interviewData]);

    useEffect(() => {
        fetchAllInterviews()
    }, [loginData.access_token])


    const handleView = (interview) => {
        setSelectedInterview(interview);
        setOpen(true);
      };



      const handleCopy = () => {
        navigator.clipboard.writeText(selectedInterview.meeting_link || '');
        toast.dismiss();
        toast.success("Meeting link copied to clipboard");
      };
    return (
        <>
            <Toaster toastOptions={{ limit: 1 }} />
            <div>
                <h2 className='menu-heading pl-0 lg:mb-2'>Interview Status</h2>
                <div className="flex gap-x-4">
                    <div className="status-box">
                        <h2>Interviews for Today</h2>
                        <h3>{interviewData.stats.interviews_for_today}</h3>
                    </div>
                    <div className="status-box">
                        <h2>Pending Interviews</h2>
                        <h3>{interviewData.stats.pending_interviews}</h3>
                    </div>
                    <div className="status-box">
                        <h2>Completed Interviews</h2>
                        <h3>{interviewData.stats.completed_interviews}</h3>
                    </div>
                </div>
                <div>
                    <h2 className='menu-heading pl-0 lg:mb-2'>Your Interviews</h2>
                    <Box sx={{ width: '100%' }} className="setting-tabs">
                        <Box sx={{ borderBottom: 0, borderColor: 'divider' }} className="hirree-tabs">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                <Tab label="Upcoming " {...a11yProps(0)} />
                                <Tab label="Completed" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {interviewData.upcoming_interviews.map((interviewDay, dayIndex) => (
                                <>
                                    <div>{interviewData.status}</div>
                                    <div key={dayIndex} className="mt-4">
                                        <h3 className="mb-2 font-semibold">{interviewDay.date}</h3>
                                        <div className="grid grid-cols-3 gap-x-4 gap-y-4" >
                                            {interviewDay.interviews.map((interview, index) => (
                                                <>
                                                <div className="interview-card relative  w-full h-full" key={index} onClick={() => handleView(interview)} >
                                                    {/* {console.log(interview)} */}
                                                    <div
                                                        className={`absolute top-2 right-2 font-medium ${
                                                        interview.status === 'accepted'
                                                            ? 'text-green-500'
                                                            : interview.status === 'declined'
                                                            ? 'text-red-500'
                                                            : interview.status === 'reschedule_requested'
                                                            ? 'text-blue-500'
                                                            : 'text-gray-500'
                                                        }`}
                                                    >
                                                        {interview.status}
                                                    </div>

                                                    <div className="flex items-center gap-x-4 mt-2">
                                                        <div className="user-img">
                                                            <img src={logoUrls[interview.company_logo] || "images/default.png"} alt="Company Logo" />
                                                        </div>
                                                        <div>
                                                            <div className="flex justify-between items-center">
                                                                <p className="text-[12px] text-[#484848] mb-1">
                                                                    <img src="images/icons/clock.svg" className="inline mr-1" />
                                                                    {new Date(`1970-01-01T${interview.interview_time.start_time}`).toLocaleTimeString('en-US', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: true
                                                                    }).replace(' ', '').toUpperCase()}-
                                                                    {new Date(`1970-01-01T${interview.interview_time.end_time}`).toLocaleTimeString('en-US', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: true
                                                                    }).replace(' ', '').toUpperCase()}
                                                                </p>

                                                            </div>
                                                            <h3 className="text-[16px] text-black mb-1">{interview.job_title}</h3>
                                                            <p className="text-[12px] text-[#86888A] mb-1">
                                                                <span>{interview.company_name} -</span>{"  "}
                                                                <span>{interview.location} -</span>{"  "}
                                                                <span>{interview.salary} Lakh CTC</span>
                                                            </p>
                                                            <p className="text-[12px] text-[#484848] mb-1">
                                                                <img src={
                                                                    interview.interview_type === 'Video call'
                                                                        ? "images/icons/video-call.svg"
                                                                        : interview.interview_type === 'Phone'
                                                                            ? "images/icons/phone.svg"
                                                                            : "images/icons/location.svg"
                                                                } className="inline mr-1" />
                                                                <span className="">{interview.interview_type}</span>
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {interviewData.completed_interviews.map((interviewDay, dayIndex) => (
                                <div key={dayIndex}>
                                    <h3 className="mb-2">{interviewDay.date}</h3> {/* Display the date */}
                                    <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-2">
                                        {interviewDay.interviews.map((interview, index) => (
                                            <div className="interview-card" key={index}>
                                                <div className="flex items-center gap-x-4 mt-2">
                                                    <div className="user-img">
                                                        <img
                                                            src={logoUrls[interview.company_logo] || "images/default.png"} // Fetch logo by ID
                                                            alt="Company Logo"
                                                        />

                                                    </div>
                                                    <div>
                                                        <p className="text-[12px] text-[#484848] mb-2">
                                                            <img src="images/icons/clock.svg" className="inline mr-1" />
                                                            {new Date(`1970-01-01T${interview.interview_time.start_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}-
                                                            {new Date(`1970-01-01T${interview.interview_time.end_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}
                                                        </p>
                                                        <h3 className="text-[16px] text-black">{interview.job_title}</h3>
                                                        <p className="text-[12px] text-[#86888A]">
                                                            <span>{interview.company_name} -</span>{"  "}
                                                            <span>{interview.location} -</span>{"  "}
                                                            <span>{interview.salary} Lakh CTC</span>
                                                        </p>
                                                        <p className="text-[12px] text-[#484848] mb-1">
                                                            <img src={
                                                                interview.interview_type === 'Video call'
                                                                    ? "images/icons/video-call.svg"
                                                                    : interview.interview_type === 'Phone'
                                                                        ? "images/icons/phone.svg"
                                                                        : "images/icons/location.svg"
                                                            } className="inline mr-1" />
                                                            <span className="">{interview.interview_type}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </CustomTabPanel>
                    </Box>
                </div>
                {/* Dialog Box for Detailed Interview View */}
                <Dialog
                    open={open}
                    onClose={onClose}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        className: 'rounded-[32px]',
                    }}
                >
                    {/* Dialog Header */}
                    <DialogTitle className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-black flex items-center justify-between mb-3">
                        <div className="flex items-center space-x-3">
                        <img
                            src={logoUrls[selectedInterview?.company_logo] || "images/default.png"}
                            alt="Company Logo"
                            className="w-10 h-10 rounded-md"
                        />
                        <div className="color-text">
                            <Typography variant="h6" component="div" className="color-text">
                            {selectedInterview?.job_title}
                            </Typography>
                            <Typography variant="subtitle2" className=" text-gray-600">
                            {selectedInterview?.company_name}, {selectedInterview?.location} 
                            <span> · ₹{selectedInterview?.salary} Lakh CTC</span>
                            </Typography>
                        </div>
                        </div>
                        <IconButton onClick={onClose} className="text-white">
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    {/* Dialog Content */}
                    <DialogContent className="p-6 ">
                    <div className="mt-4 flex items-center space-x-2">
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Current Stage"
                            value="Technical round 1"
                            InputProps={{
                            readOnly: true,
                            className: 'text-sm',
                            }}
                            disabled
                        />
                        </div>

                        <div className="grid grid-cols-2 gap-4 mt-4">
                        <div className="flex items-center space-x-2">
                            <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Interview Type"
                            value={selectedInterview?.interview_type || ''}
                            InputProps={{
                                readOnly: true,
                                className: 'text-sm',
                                startAdornment:getInterviewTypeIcon(selectedInterview?.interview_type)

                            }}
                            disabled
                            />
                        </div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Date"
                            value={
                            selectedInterview?.interview_date
                                ? new Date(selectedInterview.interview_date).toLocaleDateString('en-IN')
                                : 'N/A'
                            }
                            InputProps={{
                            startAdornment: <EventIcon className="mr-2 text-gray-400" />,
                            className: 'text-sm',
                            }}
                            disabled
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Time"
                            value={
                            selectedInterview?.interview_time
                                ? `${new Date(
                                    `1970-01-01T${selectedInterview.interview_time.start_time}`
                                ).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} - ${new Date(
                                    `1970-01-01T${selectedInterview.interview_time.end_time}`
                                ).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`
                                : 'N/A'
                            }
                            InputProps={{
                            startAdornment: <ScheduleIcon className="mr-2 text-gray-400" />,
                            className: 'text-sm',
                            }}
                            disabled
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Interviewer"
                            value={selectedInterview?.interviewer_name || 'N/A'}
                            InputProps={{
                            startAdornment: <PersonIcon className="mr-2 text-gray-400" />,
                            className: 'text-sm',
                            }}
                            disabled
                        />
                        </div>

                        <div className="mt-4">
                        {(() => {
                            switch (selectedInterview?.interview_type?.toLowerCase()) {
                            case 'phone':
                                return (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label="Phone Number"
                                    value={selectedInterview?.phone_number || 'N/A'}
                                    InputProps={{
                                    readOnly: true,
                                    startAdornment: <PhoneIcon className=" text-gray-400 mr-2" />,
                                    className: 'text-sm',
                                    }}
                                    disabled
                                />
                                );
                            case 'video call':
                                return (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label="Meeting Link"
                                    value={selectedInterview?.joining_link || 'N/A'}
                                    InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton onClick={handleCopy}>
                                        <CopyIcon />
                                        </IconButton>
                                    ),
                                    startAdornment: <VideocamIcon className=" text-gray-400 mr-2" />,
                                    className: 'text-sm',
                                    }}
                                    disabled
                                />
                                );
                            case 'in-office':
                                return (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label="Office Location"
                                    value={selectedInterview?.location || 'N/A'}
                                    InputProps={{
                                    readOnly: true,
                                    startAdornment: <BuildingIcon className=" text-gray-400 mr-2"/>,
                                    className: 'text-sm',
                                    }}
                                    disabled
                                />
                                );
                            default:
                                return (
                                <Typography variant="body2" className="text-gray-500">
                                    No additional details available.
                                </Typography>
                                );
                            }
                        })()}
                        </div>

                    </DialogContent>

                    {/* Dialog Actions */}
                    <div className="flex justify-around p-4 space-x-4">
                    {(() => {
                        switch(selectedInterview?.status ){
                            case 'scheduled':
                                return (
                                <>
                                    <Button
                                        variant="contained"
                                        className="theme-btn"
                                        onClick={handleOpenDialog}
                                        >
                                        Request Reschedule
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="theme-btn"
                                        onClick={() => handleAccept(selectedInterview?.id)}
                                        >
                                        Accept Invite
                                    </Button>
                                    <Button
                                    variant="contained"
                                    className=" bg-gradient-to-r from-red-500 to-red-400 hover:opacity-80"
                                    onClick={() => handleDecline(selectedInterview?.id)}
                                    >
                                    Decline Invite
                                    </Button>
                                </>
                            );
                            case 'accepted':
                                return (
                                    <>
                                        <Button
                                            variant="contained"
                                            className="theme-btn"
                                            onClick={handleOpenDialog}
                                            >
                                            Request Reschedule
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="theme-btn"
                                            onClick={() => handleAccept(selectedInterview?.id)}
                                            disabled
                                            >
                                            Accepted Invite
                                        </Button>
                                    </>
                                );
                            case 'declined':
                                return (
                                    <Button
                                        variant="contained"
                                        className="bg-gradient-to-r from-red-500 to-red-400 cursor-not-allowed text-white disabled:opacity-80"
                                        onClick={() => alert('Invite accepted')}
                                        disabled
                                        >
                                        declined Interview
                                    </Button>
                                );
                            case 'reschedule_requested':
                                return (
                                    <>
                                        <Button
                                            variant="contained"
                                            className="theme-btn"
                                            onClick={handleOpenDialog}
                                            disabled
                                            >
                                            Request rescheduled
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="theme-btn"
                                            onClick={() => handleAccept(selectedInterview?.id)}
                                            >
                                            Accept Invite
                                        </Button>
                                        <Button
                                        variant="contained"
                                        className=" bg-gradient-to-r from-red-500 to-red-400 hover:opacity-80"
                                        onClick={() => handleDecline(selectedInterview?.id)}
                                        >
                                        Decline Invite
                                        </Button>
                                    </>
                                );
                            default:
                                return null;
                        }
                        
                    })()}

                    </div>
                </Dialog>

                <Dialog
                    open={openR}
                    onClose={onCloseR}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        className: "rounded-[32px]",
                    }}
                    >
                    {/* Dialog Header */}
                    <DialogTitle className="bg-gradient-to-r from-[#C1EBF3] to-[#E4D0F4] text-black flex items-center justify-between py-4 px-6 mb-4">
                        <span className="font-bold text-lg">Request Reschedule</span>
                        <IconButton onClick={onCloseR} className="text-black">
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    {/* Dialog Content */}
                    <DialogContent className="p-6">
                        <form onSubmit={formik.handleSubmit}>
                        <div className="mt-4 space-y-6">
                            {/* Current Stage */}
                            <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Current Stage"
                            value="Technical Round 1"
                            InputProps={{
                                readOnly: true,
                                className: "text-sm",
                            }}
                            disabled
                            />

                            <div className="flex gap-2">
                            {/* Select Date */}

                            <TextField
                                fullWidth
                                type="date"
                                size="small"
                                label="Select Date"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: selectedInterview?.interview_date?.split("T")[0],
                                }}
                                name="date"
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                            />

                            {/* Select Time */}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Select Time Slot"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                name="timeSlot"
                                value={formik.values.timeSlot}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                formik.touched.timeSlot && Boolean(formik.errors.timeSlot)
                                }
                                helperText={formik.touched.timeSlot && formik.errors.timeSlot}
                            >
                                {generateTimeSlots(10, 17).map((slot) => (
                                <MenuItem key={slot} value={slot}>
                                    {slot}
                                </MenuItem>
                                ))}
                            </TextField>
                            </div>

                            {/* Reason for Reschedule */}
                            <TextField
                            fullWidth
                            size="small"
                            label="Reason for Reschedule"
                            placeholder="Explain Reason"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            name="reason"
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            helperText={formik.touched.reason && formik.errors.reason}
                            />
                        </div>

                        {/* Dialog Actions */}
                        <div className="p-4 flex justify-end">
                            <Button
                            type="submit"
                            variant="contained"
                            className="theme-btn "
                            >
                            <EventRepeatIcon />
                            <span>Request Reschedule</span>
                            </Button>
                        </div>
                        </form>
                    </DialogContent>
                </Dialog>







            </div>
        </>
    )
}
export default InterviewContent