import React, { Suspense } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ApplicationContent from "./applicationContent";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StatusTabs = () => {
    const [value, setValue] = React.useState(0);
    const stages = ["Applied", "In-Progress", "Hired", "Rejected"]; // Matches tabs with application stages

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <h2 className="menu-heading">My Applications</h2>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {stages.map((stage, index) => (
                            <Tab key={stage} label={stage} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </Box>
                {stages.map((stage, index) => (
                    <CustomTabPanel key={stage} value={value} index={index}>
                        {index === value && <ApplicationContent stage={stage} />}
                    </CustomTabPanel>
                ))}
            </Box>
        </>
    );
};

export default StatusTabs;
