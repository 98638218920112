export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const Auth = 'Auth';
export const SET_PARSED_CV_DATA = 'SET_PARSED_CV_DATA';
export const REMOVE_PARSE_CV_DATA='REMOVE_PARSE_CV_DATA'
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';



export const toggleDrawer = (isOpen) => ({
  type: TOGGLE_DRAWER,
  payload: isOpen,
});


export const setParsedCvData = (data) => ({
  type: SET_PARSED_CV_DATA,
  payload: data,
});


export const loginSuccess = (authData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: authData, // Contains the access token and any other authentication details
  };
};
