import React, { createContext, useContext, useState } from 'react';

// Create the context
const ApplicantsContext = createContext();

// Create a provider component
export const ApplicantsProvider = ({ children }) => {
  const [applicantsId, setApplicantsId] = useState(null);

  return (
    <ApplicantsContext.Provider value={{ applicantsId, setApplicantsId }}>
      {children}
    </ApplicantsContext.Provider>
  );
};

// Custom hook to use the ApplicantsContext
export const useApplicantsContext = () => {
  return useContext(ApplicantsContext);
};
