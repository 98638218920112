import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import LoginFooter from "../loginFooter";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Auth } from "../../../redux copy/actionTypes"
import CircularProgress from '@mui/material/CircularProgress';


const WithPassword = ({ switchToOtp }) => {
  const [form, setForm] = useState({ "password": "", "email": "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('please enter a valid password'),
    email: Yup.string()
    .email('Please enter a valid email address')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ) 
    .required('Please enter a valid email address'),
  });

  const [errors, setErrors] = useState({});

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}candidate/login`,
        data: form,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        console.log("Success:", response.data);
        dispatch({
          type: Auth,
          payload: response.data
        })
      } else {
        toast.error("Something went wrong");
      }
    }
    catch (error) {
      // Handling Yup validation errors first (to show inline errors)
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors); // Set Yup validation errors in state (inline)
        return; // Stop execution if there are Yup validation errors
      }
    
      // Handling server-side (API) errors (toast notifications only)
      if (error.response) {
        console.error("API Error:", error.response.data);
    
        // If the error response is a string (like "Please enter a valid email address")
        if (typeof error.response.data.detail === 'string') {
          toast.error(error.response.data.detail); // Show API error in toast only
        }
        // If the error is an array of errors (like the array logic you already have)
        else if (Array.isArray(error.response.data.detail)) {
          // Display a generic toast error, as you don't want to show inline errors
          toast.error("There are errors in your submission.");
    
          // Optionally, you could map through the array and display all error messages in toast notifications
          error.response.data.detail.forEach((err) => {
            toast.error(err.msg); // Display each API error message in the toast
          });
        }
      } 
      // Handling other unexpected errors
      else {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
    finally {
      setLoading(false)
    }
  };



  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
    }
  };

  return (
    <>
      <Toaster />
      <div className="">
        <form className="space-y-2" onSubmit={handleLogin}>
          <div>
            <label className="block">Email<span className="text-red-500">*</span></label>
            <input
              type="text"
              id="email"
              name="email"
              value={form.email}
              onChange={inputHandler}
              className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter your Email"
            />
            {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
          </div>
          <div>
            <label className="block">Password<span className="text-red-500">*</span></label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={form.password}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your password"
              />
              {errors.password && <span className="text-red-500 text-xs">{errors.password}</span>}

              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1 cursor-pointer"
              >
                {showPassword ? <VisibilityOffIcon fontSize="14px"/> : <VisibilityIcon fontSize="14px"/>}
              </span>
            </div>
          </div>
          {/* <div className="flex items-center justify-between mb-4"> */}
          {/* <div>
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me<span className="text-red-500">*</span></label>
            </div> */}
          <div>
            <Link to="#" onClick={switchToOtp} className="float-right mb-3 text-blue-500"
              style={{ fontFamily: "poppins medium" }}>Login with OTP</Link>
          </div>
          {/* </div> */}
          <div>
            <button type="submit" className="theme-btn mr-2 text-white w-full rounded-md" >Sign In
              {loading == true ?
                <CircularProgress
                  size={20}
                  disableShrink
                  style={{ color: "#fff", margin: "0 10px" }} />
                : null}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default WithPassword;
