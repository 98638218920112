import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from './Appbar';
import Drawer from './Drawer';
import MainContent from './MainContent';


export default function EmployerDashboard() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <MainContent />
    </Box>
  );
}
