import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import DrawerHeader from '../dashboard/DrawerHeader';
import config from '../../../config/config';
import Modal from '@mui/material/Modal';
import PostJobForm from '../Job-post/postFrom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getJobs } from '../../../utils/employer.api/employer.api.services';
import toast, { Toaster } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteJobs from './deletJobs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px"
};
const JobStatus = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const [jobData, setJobData] = useState({
        openedJobs: [],
        onHoldJobs: [],
        closedJobs: []
    });

    const fetchJobs = async () => {
        try {
            const response = await getJobs(loginData.access_token)

            if (response.status === 200) {
                setJobData({
                    openedJobs: response.data['Opened Jobs'].jobs,
                    onHoldJobs: response.data['On Hold Jobs'].jobs,
                    closedJobs: response.data['Closed Jobs'].jobs
                });
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
    };
    useEffect(() => {
        fetchJobs();
    }, [loginData.access_token]);
    const [selectedJob, setSelectedJob] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [jobToUpdate, setJobToUpdate] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(null);
  

    const handleOpenDelete = (jobId) => {
      setSelectedJobId(jobId);
      setOpenDeleteModal(true);
    };
  
    // Close modal
    const handleCloseDelete = () => {
     setSelectedJobId(null); // Reset job ID
    setOpenDeleteModal(false);
    };
    
    const handleCloseModal = () => {

        setIsModalOpen(false); // Close the modal
        setSelectedJob(null);  // Clear the selected job data
    };
    const updateJobStatus = async (jobId, status, feedback) => {
        try {
            const response = await axios.patch(
                `${config.apiUrl}employer/jobs/${jobId}/status`,
                null,
                {
                    params: {
                        job_status: status,
                        feedback: status === 'Opened' ? '' : feedback,
                    },
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 200) {

                // Update the local state immediately
                setJobData((prevData) => {
                    const updatedOpenedJobs = status === 'Opened'
                        ? [...prevData.openedJobs, prevData.onHoldJobs.find((job) => job.id === jobId) || prevData.closedJobs.find((job) => job.id === jobId)]
                        : prevData.openedJobs.filter((job) => job.id !== jobId);

                    const updatedOnHoldJobs = status === 'On Hold'
                        ? [...prevData.onHoldJobs, prevData.openedJobs.find((job) => job.id === jobId) || prevData.closedJobs.find((job) => job.id === jobId)]
                        : prevData.onHoldJobs.filter((job) => job.id !== jobId);

                    const updatedClosedJobs = status === 'Closed'
                        ? [...prevData.closedJobs, prevData.openedJobs.find((job) => job.id === jobId) || prevData.onHoldJobs.find((job) => job.id === jobId)]
                        : prevData.closedJobs.filter((job) => job.id !== jobId);

                    return {
                        openedJobs: updatedOpenedJobs,
                        onHoldJobs: updatedOnHoldJobs,
                        closedJobs: updatedClosedJobs,
                    };
                });

                // Optionally, you can still call fetchJobs to ensure data consistency
                // fetchJobs();
            }
        } catch (error) {
            console.error('Error updating job status:', error);
        }
    };

    // Drag and Drop
    const JobCard = ({ job, status }) => {
        const [{ isDragging }, dragRef] = useDrag({
            type: 'jobCard',
            item: { job, status },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            })
        });

        return (
            <div
                ref={dragRef}
                style={{ opacity: isDragging ? 0.5 : 1 }}
                className="status-card mt-7"
            >
                <div className={`job-card card1 ${status === 'Opened' ? 'blue' : status === 'On Hold' ? 'yellow' : 'red'}`}>
                    <button className="view-black theme-btn" >
                        <Link to={`${config.employerUrl}post-job`} state={{ job,step : 1,canDelete : true }} >View</Link>
                    </button>
                    {/* <button type='button'
                    onClick={() => handleOpenDelete(job.id)}
                    className='p-0' style={{ float: "right", margin: "7px 7px 0 0", color: "red" }}>
                        <DeleteIcon sx={{ fontSize: "30px" }} />
                    </button> */}
               
                    <div className="p-2  relative overflow-hidden">
                        <div className='view-user'>
                            <Link to={`${config.employerUrl}total-applicants/${job.id}`}>
                                <img src='images/icons/multi-users.svg' />
                                <h4>View<br /> Applicants</h4>
                            </Link>
                        </div>
                        <div className='ml-12 mt-4 pr-16'>
                            <h3>{job.title}</h3>
                            <span>{job.salary_min} - {job.salary_max} CTC, {job.location}</span>
                        </div>
                        <div className="mt-2 type-btns flex">
                            <div><img src="images/icons/location.svg" />{job.location}</div>
                            <div className="mx-1"><img src="images/icons/cap.svg" />{job.job_modes}</div>
                            <div><img src="images/icons/clock.svg" />{job.experience}</div>

                        </div>
                        <div className='type-btns flex'>
                            <div><img src="images/icons/clock.svg" />{job.job_types}</div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between px-4 py-3" style={{ background: "#fff" }}>
                        <span>{job.posted_date}</span>
                        <span>N/p: {job.notice_period}</span>
                    </div>
                </div>
            </div>
        );
    };

    const JobSection = ({ title, status, jobs, onDropJob }) => {
        const [{ isOver }, dropRef] = useDrop({
            accept: 'jobCard',
            drop: (item) => {
                if (item.status !== status) {
                    onDropJob(item.job, status);
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            })
        });

        return (
            <>
                <Toaster />
                <div ref={dropRef} className={`job-section ${status.toLowerCase()} mb-3`} style={{ backgroundColor: isOver ? '#e0e0e0' : 'inherit' }}>
                    <div className="section-name mb-3">
                        <div className="dot"></div>
                        <span>{title} ({jobs.length})</span>
                    </div>
                    {jobs.length > 0 ? (
                        jobs.map((job, index) => (
                            <JobCard key={index} job={job} status={status} />
                        ))
                    ) : (
                        <div className='text-center'>No {title.toLowerCase()} jobs</div>
                    )}
                    
                </div>
                  
            </>
        );
    };

    const handleDropJob = (job, status) => {
        // Determine the old status of the job
        let oldStatus;
        if (jobData.openedJobs.includes(job)) {
            oldStatus = 'Opened';
        } else if (jobData.onHoldJobs.includes(job)) {
            oldStatus = 'On Hold';
        } else if (jobData.closedJobs.includes(job)) {
            oldStatus = 'Closed';
        }

        // If the new status is "On Hold" or "Closed", open the feedback modal
        if (status === 'On Hold' || status === 'Closed') {
            setJobToUpdate(job);
            setNewStatus(status);
            setIsFeedbackModalOpen(true);
        } else {
            // If moving to "Opened", update status directly without feedback
            updateJobStatus(job.id, status, '');
        }
    };
    const handleSubmitFeedback = (feedback) => {
        updateJobStatus(jobToUpdate.id, newStatus, feedback);
        setIsFeedbackModalOpen(false);
        setJobToUpdate(null);
        setNewStatus('');
    };

    const FeedbackModal = ({ isOpen, onClose, onSubmitFeedback }) => {
        const [localFeedback, setLocalFeedback] = useState('');
        const [errorMessage, setErrorMessage] = useState('');

        const handleInputChange = (e) => {
            setLocalFeedback(e.target.value);
        };

        const handleSubmit = () => {
            if (localFeedback.trim() === '') {
                toast.error('Feedback is required.');
                return;
            }
            onSubmitFeedback(localFeedback);
            setLocalFeedback('');
            setErrorMessage('');
        };

        return (
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style}>
                    <h2 className='heading2 mb-2'>Provide Feedback</h2>
                    <textarea
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        value={localFeedback}
                        onChange={handleInputChange}
                        placeholder="Enter your feedback here..."
                    ></textarea>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    <div className='flex justify-end mt-4'>
                        <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={onClose}>
                            <span className="color-text">Cancel</span>
                        </button>
                        <button type="button" className="theme-btn bordered-btn py-2" onClick={handleSubmit}>
                            <span className="color-text">Submit</span>
                        </button>
                    </div>
                </Box>
            </Modal>
        );
    };



    return (
        <DndProvider backend={HTML5Backend}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent mt-0 sm:-mt-[10px]">
                <DrawerHeader />
                <div>
                    <h2 className="menu-heading">Job Listing</h2>
                </div>
                <div className="p-4">
                    <div className="grid gap-x-6 gap-y-3 mb-6 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
                        <JobSection title="Opened Jobs" status="Opened" jobs={jobData.openedJobs} onDropJob={handleDropJob} />
                        <JobSection title="On Hold Jobs" status="On Hold" jobs={jobData.onHoldJobs} onDropJob={handleDropJob} />
                        <JobSection title="Closed Jobs" status="Closed" jobs={jobData.closedJobs} onDropJob={handleDropJob} />
                    </div>
                </div>
                {isFeedbackModalOpen && (
                    <FeedbackModal
                        isOpen={isFeedbackModalOpen}
                        onClose={() => setIsFeedbackModalOpen(false)}
                        onSubmitFeedback={handleSubmitFeedback}
                    />
                )}

                {isModalOpen && (
                    <Modal open={isModalOpen} onClose={handleCloseModal}>
                        <Box sx={style}>
                            <PostJobForm handleCloseFrom={handleCloseModal} initialData={selectedJob} />
                        </Box>
                    </Modal>
                )}
                   <DeleteJobs
                      openDeleteModal={openDeleteModal}
                      handleCloseDelete={handleCloseDelete}
                      jobId={selectedJobId} 
                    //   handleJobDelete={handleJobDelete}
                    />
            </Box>
        </DndProvider>
    );

};

export default JobStatus;
